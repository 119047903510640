import { useEffect, useMemo, useState } from "react"

import type { UnlikelySelectedOption } from "@unlikelystudio/commerce-connector"

import type { Nullish } from "~/@types/generics"
import type { ColorCustomAttribute } from "~/lib/shopify/constants"
import type { TVariant } from "~/lib/shopify/serializers/serialize-variants"
import {
  getOptionsForSingleProduct,
  getUniqOptionsValues,
} from "~/components/ui/Product/ProductHeader/_data/utils/get-uniq-variants-without-color"
import { getVariantColor } from "~/components/ui/Product/ProductHeader/_data/utils/get-variant-color"
import { getVariantConfort } from "~/components/ui/Product/ProductHeader/_data/utils/get-variant-confort"
import { getVariantFromOption } from "~/components/ui/Product/ProductHeader/_data/utils/get-variant-from-option"
import { getVariantSize } from "~/components/ui/Product/ProductHeader/_data/utils/get-variant-size"
import { type TCrossSellProductCard } from "~/components/globals/Cart/components/CrossSell/_data/serializeCrossSellProductCard"

type UseCrossSellProductCardProps = {
  item: TCrossSellProductCard
  selectedOptions?: UnlikelySelectedOption[]
}

export function useCrossSellProductCard({ item, selectedOptions }: UseCrossSellProductCardProps) {
  const preSelectedOptions = useMemo(() => {
    return selectedOptions ? findClosestVariantFromSelectedOptions(item.variants, selectedOptions) : undefined
  }, [item.variants, selectedOptions])

  const [color, setColor] = useState(() => preSelectedOptions?.color ?? item.defaultColor?.value)

  const variantsFromCurrentColor = item?.variants

  const optionsWithValuesSelectSelector = useMemo(
    () => getOptionsForSingleProduct(variantsFromCurrentColor ?? []) ?? [],
    [variantsFromCurrentColor]
  )

  const optionsConfort = getUniqOptionsValues(optionsWithValuesSelectSelector, "confort")
  const optionsSize = getUniqOptionsValues(optionsWithValuesSelectSelector, "size")

  const [confort, setConfort] = useState(preSelectedOptions?.confort ?? optionsConfort?.[0]?.value ?? null)
  const [size, setSize] = useState(preSelectedOptions?.size ?? optionsSize?.[0]?.value ?? null)

  const variant = useMemo(() => {
    const currentVariantId = optionsWithValuesSelectSelector?.find(
      (option) => option.confort === confort && option.size === size
    )?.id
    return getVariantFromOption(variantsFromCurrentColor, currentVariantId)
  }, [variantsFromCurrentColor, confort, size])

  const image = variant?.image?.[0]

  const price = useMemo(() => variant?.price, [variant])

  const dotColor = useMemo(() => {
    const currentColorWithDotImage = item.colorsWithDotImage?.find(
      (colorWithDotImage) => colorWithDotImage?.value === color
    )
    return {
      image: currentColorWithDotImage?.image,
      value: color,
    } satisfies ColorCustomAttribute
  }, [color])

  useEffect(() => {
    const preSelectedOptions = selectedOptions
      ? findClosestVariantFromSelectedOptions(item.variants, selectedOptions)
      : undefined

    if (preSelectedOptions?.color) setColor(preSelectedOptions.color)
    if (preSelectedOptions?.confort) setConfort(preSelectedOptions.confort)
    if (preSelectedOptions?.size) setSize(preSelectedOptions.size)
  }, [selectedOptions])

  return [
    {
      color,
      confort,
      size,
      variant,
      image,
      price,
      dotColor,
      optionsConfort,
      optionsSize,
    },
    { setColor, setConfort, setSize },
  ] as const
}

function getOptionsFromVariant(variant: Nullish<Pick<TVariant, "selectedOptions">>) {
  if (!variant) return null
  return {
    size: getVariantSize(variant),
    confort: getVariantConfort(variant),
    color: getVariantColor(variant),
  }
}

function findClosestVariantFromSelectedOptions(variants: TVariant[], selectedOptions: UnlikelySelectedOption[]) {
  let maxScore = 0
  let exactSize = false
  const currentOptions = getOptionsFromVariant({ selectedOptions })

  // Find the variant with the highest score (more matching options)
  const processedOptions = variants
    .map((variant) => {
      const options = getOptionsFromVariant(variant)
      const score = currentOptions && options ? getScore(currentOptions, options) : 0

      if (score > maxScore) maxScore = score
      if (currentOptions?.size && options?.size && currentOptions.size === options.size) exactSize = true

      return {
        options,
        score,
      }
    })
    .filter((v) => v.score === maxScore)

  // If there is no size exact match, return the first option with a size greater than or equal to the current size
  const options = !exactSize
    ? [
        processedOptions.find(
          (e) => (e.options?.size?.split("x")?.[0] ?? 0) >= (currentOptions?.size?.split("x")?.[0] ?? 0)
        ) ??
          // fallback
          processedOptions?.[0],
      ]
    : processedOptions

  // Return the first option with the highest score
  return options?.[0]?.options ?? undefined
}

/**
 * The function `getScore` compares two sets of options and returns a score based on the number of
 * matching values.
 * @param options1 - The `options1` parameter in the `getScore` function is expected to be the result
 * of calling the `getOptionsFromVariant` function and then applying the `NonNullable` utility type to
 * ensure that the return value is not `null` or `undefined`. This parameter should contain options for
 * @param options2 - The `options2` parameter in the `getScore` function is expected to be the result
 * of calling the `getOptionsFromVariant` function and then applying the `NonNullable` utility type to
 * ensure that the return value is not `null` or `undefined`.
 * @returns The `getScore` function returns the total number of matching values between `options1` and
 * `options2`.
 */
function getScore(
  options1: NonNullable<ReturnType<typeof getOptionsFromVariant>>,
  options2: NonNullable<ReturnType<typeof getOptionsFromVariant>>
) {
  return Object.keys(options1).reduce((acc, key) => {
    const value1 = options1[key as keyof typeof options1]
    const value2 = options2[key as keyof typeof options2]
    if (!value1 || !value2) return acc
    return acc + (value1 === value2 ? 1 : 0)
  }, 0)
}
