import type { Nullish } from "~/@types/generics"
import { freeProductsInCartMetaobjectSchema } from "~/lib/shopify/metaobjects/free-products-in-cart"
import { serializeMetaobject } from "~/lib/shopify/metaobjects/serializer"
import { getSfMetaobjects } from "~/lib/shopify/queries/get-sf-metaobjects"
import { serializeFreeProductInCart } from "~/data/free-products-in-cart/serializer"

export async function getFreeProductsInCart(locale: Nullish<string>) {
  const { metaobjects } = await getSfMetaobjects({ locale, first: 100, type: "free_product_in_cart" })
  const parsedFreeProductsInCart = metaobjects
    ?.map((metaobject) => serializeMetaobject(freeProductsInCartMetaobjectSchema, metaobject))
    ?.filter(Boolean)

  const serializedFreeProductsInCart = await Promise.all(
    parsedFreeProductsInCart.map((metaobject) => serializeFreeProductInCart(metaobject, locale))
  )

  return serializedFreeProductsInCart.filter(Boolean)
}
