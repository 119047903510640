import { z } from "zod"

import { gidSchema } from "~/lib/zod/utils/gid-schema"

export const productColorMetaobjectSchema = z.object({
  color_dot_image: gidSchema,
  color_title: z.string(),
})

export type TProductColorMetaobject = z.infer<typeof productColorMetaobjectSchema>
