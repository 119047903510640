import type { Nullish } from "~/@types/generics"
import {
  ONLY_LETTERS_NUMBERS_AMPERSAND_ACCENTS_ESZETT_SPACE_AND_DASH,
  ONLY_LETTERS_NUMBERS_AMPERSAND_ACCENTS_SPACE_AND_DASH,
} from "~/components/ui/Form/utils/regex"

export function getCurrentEmbroideryFontRegex(currentFont: Nullish<string>) {
  switch (currentFont) {
    case "bâton":
      return ONLY_LETTERS_NUMBERS_AMPERSAND_ACCENTS_ESZETT_SPACE_AND_DASH

    case "rétro":
      return ONLY_LETTERS_NUMBERS_AMPERSAND_ACCENTS_SPACE_AND_DASH

    case "mano":
      return ONLY_LETTERS_NUMBERS_AMPERSAND_ACCENTS_SPACE_AND_DASH

    default:
      return ONLY_LETTERS_NUMBERS_AMPERSAND_ACCENTS_SPACE_AND_DASH
  }
}
