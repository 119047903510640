import type { ComponentProps, ReactNode } from "react"

import type { Nullish } from "~/@types/generics"
import { sanitizeEmbroideryPrice } from "~/components/ui/Product/ProductHeader/PanelEmbroidery/EmbroideryForm/utils"
import { Stack } from "~/components/abstracts/Stack"

import { text } from "~/styles/variants"

type PanelEmbroideryFormItemProps = {
  suptitle?: ReactNode
  title: ReactNode
  subtitle?: ReactNode
  description?: ReactNode
  count?: number
  oneEmbroideryPrice?: Nullish<string>
  wrapperColumnResponsive?: boolean
} & ComponentProps<typeof Stack>

export function PanelEmbroideryFormItem({
  suptitle,
  title,
  subtitle,
  description,
  count,
  oneEmbroideryPrice,
  sprinklesCss,
  wrapperColumnResponsive,
  children,
  ...props
}: PanelEmbroideryFormItemProps) {
  return (
    <Stack
      gap={10}
      direction="column"
      sprinklesCss={{
        pY: 20,
        borderColor: "grey-border",
        borderStyle: "solid",
        borderTopWidth: 1,
        ...(sprinklesCss ?? {}),
      }}
      {...props}
    >
      <Stack direction="column" gap={16}>
        {suptitle && (
          <p
            className={text({
              design: "riposte-13",
              lineHeight: 1.45,
              letterSpacing: 1,
              transform: "uppercase",
              weight: "medium",
            })}
          >
            {`${suptitle} ${count ? count : ""} ${sanitizeEmbroideryPrice(oneEmbroideryPrice)}`}
          </p>
        )}
        <Stack
          gap={20}
          direction={{ mobile: wrapperColumnResponsive ? "column" : "row", tablet: "row" }}
          sprinklesCss={{
            justifyContent: "space-between",
          }}
        >
          <Stack direction="column" gap={2}>
            <p
              className={text({
                design: "riposte-13",
                lineHeight: 1.45,
                letterSpacing: 1,
                transform: "uppercase",
                weight: "medium",
              })}
            >
              {title}
            </p>
            {subtitle && (
              <p className={text({ design: "riposte-13", color: "blue-light-txt", lineHeight: 1.5 })}>{subtitle}</p>
            )}
          </Stack>
          {children}
        </Stack>
      </Stack>
      {description && (
        <p className={text({ design: "riposte-10", color: "blue-light-txt", lineHeight: 1.3 })}>{description}</p>
      )}
    </Stack>
  )
}
