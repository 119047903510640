import { useEffect } from "react"

import { logger } from "~/utils/logger"

type TExt = "woff" | "woff2" | "ttf" | "otf" | "eot"

export function useLoadFont(fontFace: string, fileName: string, ext: TExt = "woff2", timeout = 0) {
  useEffect(() => {
    const font = new FontFace(fontFace, `url(/fonts/${fileName}/${fileName}.${ext})`)

    if (font?.status === "unloaded") {
      setTimeout(() => {
        font
          .load()
          .then((fontLoaded) => document.fonts.add(fontLoaded))
          .catch((error) => logger.error(`[FontFace ERROR] Font ${fontFace} could not be loaded: ${error}`))
      }, timeout)
    }
  }, [fontFace, fileName, ext])
}
