import { z } from "zod"

import type { Nullish } from "~/@types/generics"
import { FONTS } from "~/components/ui/Product/ProductHeader/PanelEmbroidery/EmbroideryForm/EmbroideryFontSelector"
import { PLACEMENTS } from "~/components/ui/Product/ProductHeader/PanelEmbroidery/EmbroideryForm/EmbroideryPlacementSelector"
import { getEmbroideriesWithValues } from "~/components/ui/Product/ProductHeader/PanelEmbroidery/EmbroideryForm/utils"
import type { useTranslate } from "~/providers/I18nProvider/hooks/useTranslate"
import { getCurrentEmbroideryFontRegex } from "~/utils/get-current-embroidery-font-regex"

function getEmbroiderySchema(
  embroideryLength: number,
  t: ReturnType<typeof useTranslate>,
  currentFont: Nullish<string>
) {
  return z
    .string({ required_error: t("error_required") })
    .max(embroideryLength, t("error_max", { max: embroideryLength }))
    .regex(getCurrentEmbroideryFontRegex(currentFont), t("error_character_not_allowed"))
}

export function getEmbroideryFormSchema({
  t,
  embroideryLength,
  isDoubleEmbroidery,
  currentFont,
}: {
  t: ReturnType<typeof useTranslate>
  embroideryLength: number
  isDoubleEmbroidery: boolean
  currentFont: Nullish<string>
}) {
  const embroiderySchema = getEmbroiderySchema(embroideryLength, t, currentFont)

  return z
    .object({
      font: z.enum(FONTS as [(typeof FONTS)[number]]),
      placement: z.enum(PLACEMENTS as [(typeof PLACEMENTS)[number]]),
      color: z.string(),
      embroideries: z
        .array(
          z.object({
            value: embroiderySchema.optional(),
            picto: z.string(),
          })
        )
        .transform(getEmbroideriesWithValues),
      // Only used for embroideries validation
      embroideryNotEmpty: z.any(),
    })
    .refine(({ embroideries }) => (isDoubleEmbroidery ? embroideries.length > 0 : true), {
      message: t("error_at_least_one_embroidery"),
      path: ["embroideryNotEmpty"],
    })
}

export type TEmbroideryFormData = z.infer<ReturnType<typeof getEmbroideryFormSchema>>
