import { z } from "zod"

import { parseShopifyReferencesFromMetatield } from "~/lib/shopify/utils/parse-references-from-metafields"
import { parseTextsFromMetafield } from "~/lib/shopify/utils/parse-texts-from-metafields"
import { gidSchema } from "~/lib/zod/utils/gid-schema"

const coerceNumberSchema = z.coerce
  .number()
  .transform((value) => (isNaN(value) ? null : value))
  .nullish()

export const freeProductsInCartConditionsMetaobjectSchema = z.object({
  disabled: z.string().transform((value) => value === "true"),
  product: gidSchema.nullish(),
  out_of_stock_quantity: coerceNumberSchema,
  minimum_cart_price_condition: coerceNumberSchema,
  products_condition: z.string().transform(parseShopifyReferencesFromMetatield).nullish(),
  collections_condition: z.string().transform(parseShopifyReferencesFromMetatield).nullish(),
})

export const freeProductsInCartMetaobjectSchema = z
  .object({
    image: gidSchema.nullish(),
    title: z.string(),
    text: z.string().nullish(),
    ranking_condition: z.coerce.number(),
    hide_variant_on_specific_markets: z.string().nullish().transform(parseTextsFromMetafield),
  })
  .merge(freeProductsInCartConditionsMetaobjectSchema)

export type TFreeProductsInCartMetaobject = z.infer<typeof freeProductsInCartMetaobjectSchema>
export type TFreeProductsInCartConditionsMetaobject = z.infer<typeof freeProductsInCartConditionsMetaobjectSchema>
