import { useMutation, type UseMutationOptions } from "@tanstack/react-query"

import type { GID } from "@unlikelystudio/commerce-connector"

import { KLAVIYO_BACK_IN_STOCK_PARAMS } from "~/lib/api/constants"
import { requestNextApiEndpoint } from "~/lib/api/utils/request-endpoint"
import { ALERT_CODES } from "~/lib/constants"
import { useAlerts } from "~/hooks/useAlerts"

export type KlaviyoBackInStockMutationParams = {
  email: string
  acceptsMarketing: boolean
  variantId: GID
}

type KlaviyoBackInStockMutationResponse = { success: boolean }

export const useKlaviyoBackInStockMutation = (
  mutationOptions?: UseMutationOptions<KlaviyoBackInStockMutationResponse, unknown, KlaviyoBackInStockMutationParams>
) => {
  const triggerAlert = useAlerts()

  return useMutation<KlaviyoBackInStockMutationResponse, unknown, KlaviyoBackInStockMutationParams>(
    async ({ email, acceptsMarketing, variantId }) => {
      const res = await requestNextApiEndpoint(KLAVIYO_BACK_IN_STOCK_PARAMS, {
        payload: {
          acceptsMarketing,
          email,
          variantId: variantId ?? "",
        },
      })

      return res as KlaviyoBackInStockMutationResponse
    },
    {
      onError: (...props) => {
        triggerAlert(ALERT_CODES.SERVER_ERROR)
        mutationOptions?.onError?.(...props)
      },
      ...mutationOptions,
    }
  )
}
