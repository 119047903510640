"use client"

import clsx from "clsx"

import type { PropsWithClassName } from "~/@types/generics"
import { PRODUCT_CUSTOM_ATTRIBUTES } from "~/lib/shopify/constants"
import { useAddToCart } from "~/lib/shopify/hooks/useAddToCart"
import type { TProductCardsWithVariants } from "~/lib/shopify/serializers/serialize-product-cards-with-variants"
import type { UnlikelyCartLineItemInput } from "~/lib/shopify/types"
import { Image, type Sizes } from "~/components/ui/Image"
import InlineCta from "~/components/ui/InlineCta"
import { Stack } from "~/components/abstracts/Stack"
import { useTranslate } from "~/providers/I18nProvider/hooks/useTranslate"

import { sprinkles } from "~/styles/sprinkles.css"
import { text } from "~/styles/variants"

import * as css from "./styles.css"

export type SmallProductCardProps = PropsWithClassName<{
  product: TProductCardsWithVariants[0]
  sizesFromBreakpoint: Sizes
  isFromCartCrossSell?: boolean
}>

function SmallProductCard({
  className,
  product,
  sizesFromBreakpoint,
  isFromCartCrossSell = false,
}: SmallProductCardProps) {
  const { mutate: addCartLines } = useAddToCart()
  const t = useTranslate()

  // This slider doesn't have direction, we add product without knowing his size, color etc..
  const firstVariant = product.variants[0]

  if (!firstVariant) return null

  const productLineItem = {
    quantity: 1,
    merchandiseId: firstVariant.id ? firstVariant.id : "",
    attributes: [
      ...(isFromCartCrossSell
        ? [
            {
              key: PRODUCT_CUSTOM_ATTRIBUTES.IS_FROM_CART_CROSS_SELL,
              value: "true",
            },
            {
              key: PRODUCT_CUSTOM_ATTRIBUTES.COLLECTION_IDS,
              value: JSON.stringify(product?.collections?.map((collection) => collection.id)),
            },
          ]
        : []),
    ],
  } satisfies UnlikelyCartLineItemInput

  //Only unique items in the slider (unique size & unique color), so get the first variant
  const onClick = () => {
    addCartLines([productLineItem])
  }

  return (
    <div data-comp="UI/SmallProductCard" className={clsx(className, css.SmallProductCard)}>
      {firstVariant.image?.[0] && (
        <Image
          className={css.image}
          asPlaceholder
          ratio="1/1"
          sizesFromBreakpoints={sizesFromBreakpoint}
          objectFit={({ isPNG }) => (isPNG ? "contain" : "cover")}
          {...firstVariant.image[0]}
        />
      )}
      <Stack gap={8} sprinklesCss={{ pL: 4, alignItems: "flex-start", justifyContent: "center" }} direction="column">
        <div
          className={text({
            design: "riposte-13",
            color: "blue-deep",
          })}
        >
          {product.title}
        </div>
        <div
          className={clsx(
            text({
              design: "riposte-11",
              color: "blue-light-txt",
            })
          )}
        >
          {firstVariant.price}
        </div>
        <InlineCta
          customLineActive
          className={clsx(sprinkles({ cursor: firstVariant.availableForSale ? "pointer" : "default" }), css.button)}
          onClick={onClick}
        >
          {firstVariant.availableForSale ? t("product_card_add") : t("product_card_unavailable")}
        </InlineCta>
      </Stack>
    </div>
  )
}

export default SmallProductCard
