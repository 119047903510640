"use client"

import type { ComponentProps } from "react"
import clsx from "clsx"

import type { Nullish } from "~/@types/generics"
import { Image } from "~/components/ui/Image"
import { type TImage } from "~/components/ui/Image/_data/serializer"
import { EMBROIDERY_NONE_PICTO } from "~/components/ui/Product/ProductHeader/PanelEmbroidery/EmbroideryForm/utils"
import { Stack } from "~/components/abstracts/Stack"
import { useTranslate } from "~/providers/I18nProvider/hooks/useTranslate"

import { sprinkles } from "~/styles/sprinkles.css"
import { text } from "~/styles/variants"

import * as css from "./styles.css"

export type EmbroideryPictosProps = {
  image: TImage
  value: string
  text: string
}[]

type EmbroideryPictoSelectorProps = {
  currentPicto: Nullish<string>
  embroideryPictos: EmbroideryPictosProps
  onChange: (newPicto: string) => void
} & ComponentProps<typeof Stack>

export function EmbroideryPictoSelector({
  embroideryPictos,
  currentPicto,
  onChange,
  className,
  ...props
}: EmbroideryPictoSelectorProps) {
  const t = useTranslate()

  if (!embroideryPictos) return null

  return (
    <Stack
      gap={12}
      className={clsx(css.EmbroideryPictoSelector, className)}
      sprinklesCss={{ flexWrap: "wrap", justifyContent: "center" }}
      {...props}
    >
      {embroideryPictos?.length > 0 && (
        <button
          type="button"
          onClick={() => {
            onChange(EMBROIDERY_NONE_PICTO)
          }}
          className={clsx(
            css.PictoNoneSelectorItem,
            text({ design: "riposte-12", transform: "lowercase", color: "black" }),
            sprinkles({
              borderColor: currentPicto === EMBROIDERY_NONE_PICTO ? "blue-deep" : "grey-light-bg",
              pX: 16,
              pY: 10,
              backgroundColor: "transparent",
              borderStyle: "solid",
              cursor: "pointer",
              borderWidth: 1,
            })
          )}
        >
          {t("embroidery_picto_none")}
        </button>
      )}
      {embroideryPictos?.map((picto, index) => {
        const isActive = currentPicto === picto.value
        return (
          <button
            key={`item_font_${index}`}
            type="button"
            onClick={() => {
              onChange(picto.value)
            }}
            className={clsx(
              css.PictoSelectorItem,
              sprinkles({
                borderColor: isActive ? "blue-deep" : "grey-light-bg",
                backgroundColor: "grey-light-bg",
                borderStyle: "solid",
                cursor: "pointer",
                borderWidth: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                pX: 6,
                pY: 6,
              })
            )}
          >
            <Image {...picto.image} asPlaceholder noBackground />
          </button>
        )
      })}
    </Stack>
  )
}
