import type { UnlikelyProduct } from "@unlikelystudio/commerce-connector"

import type { Nullish, SetPartialKeys } from "~/@types/generics"
import { getProductMetafieldValue } from "~/lib/shopify/constants"

export function getProductTitle(
  product: Nullish<SetPartialKeys<Pick<UnlikelyProduct, "metafields" | "title">, "metafields">>
) {
  const displayName = getProductMetafieldValue(product?.metafields, "DISPLAY_NAME")

  return displayName || product?.title
}
