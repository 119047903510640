"use client"

import { useRef } from "react"

import type { GID } from "@unlikelystudio/commerce-connector"

import type { Nullish } from "~/@types/generics"
import { ALERT_CODES } from "~/lib/constants"
import { useKlaviyoBackInStockMutation } from "~/lib/shopify/hooks/useKlaviyoBackInStockMutation"
import { useAlerts } from "~/hooks/useAlerts"
import { EmailAndPrivacyForm } from "~/components/ui/EmailAndPrivacyForm"
import { Link } from "~/components/ui/Link"
import RichText from "~/components/abstracts/RichText"
import { Stack } from "~/components/abstracts/Stack"
import type { TPopinBackInStock } from "~/components/globals/PopinBackInStock/_data/serializer"
import { Popin, usePopin } from "~/managers/PopinManager"

import { text } from "~/styles/variants"

import * as css from "./styles.css"

export type PopinBackInStockProps = {
  onSuccess?: () => void
  form: NonNullable<TPopinBackInStock>["form"]
  variantId: GID
  productName: Nullish<string>
}

export function PopinBackInStock({ onSuccess, form, variantId, productName }: PopinBackInStockProps) {
  const popinRef = useRef(null)
  const triggerAlert = useAlerts()
  const { removeCurrent } = usePopin()

  const { mutate: backInStockMutation, isLoading } = useKlaviyoBackInStockMutation({
    onSuccess: (data) => {
      if (data.success) {
        triggerAlert(ALERT_CODES.BACK_IN_STOCK_SUCCESS)
        onSuccess?.()
        removeCurrent()
      } else {
        triggerAlert(ALERT_CODES.SERVER_ERROR)
      }
    },
    onError: () => {
      triggerAlert(ALERT_CODES.SERVER_ERROR)
    },
  })

  return (
    <Popin clickOutsideRef={popinRef}>
      <div className={css.PopinBackInStock} ref={popinRef} data-comp="UI/PopinBackInStock">
        <Popin.CloseButton />
        {form && (
          <Stack direction="column" gap={24}>
            <RichText
              className={text({
                design: "spezia-24",
                transform: "uppercase",
                weight: "semiBold",
                lineHeight: 1,
                letterSpacing: 0.6,
                color: "blue-deep",
              })}
              render={form.title}
            />

            {productName && <div>{productName}</div>}
            <p
              className={text({
                design: "riposte-13",
                lineHeight: 1.5,
                color: "blue-light-txt",
              })}
            >
              {form.description}&thinsp;
              <Link className={text({ color: "blue-deep" })} {...form.linkToPrivacyPolicy} />
            </p>
            <EmailAndPrivacyForm
              isLoading={isLoading}
              formData={form}
              onSubmit={(data) => {
                backInStockMutation({ email: data.email, acceptsMarketing: data.acceptsMarketing, variantId })
              }}
            />
          </Stack>
        )}
      </div>
    </Popin>
  )
}
