import type { UnlikelyCart } from "@unlikelystudio/commerce-connector"

import type { Nullish } from "~/@types/generics"
import { getProductCustomAttributesValue } from "~/lib/shopify/constants"
import type { TFreeProductInCart } from "~/data/free-products-in-cart/serializer"

export function getFreeProductsEligibleInCart(
  freeProducts: NonNullable<TFreeProductInCart>[] | undefined,
  cartData: { cart: Nullish<UnlikelyCart> }
) {
  const { cart } = cartData

  const totalPrice = cart?.cost?.totalAmount?.amount

  // Filter by defined conditions (price, collections, products)
  const resultFilteredData = freeProducts?.filter((item) => {
    const meetsPriceCondition = item?.minimumCartPriceCondition
      ? totalPrice && item.minimumCartPriceCondition < totalPrice
      : true

    const meetsCollectionsCondition =
      item?.collectionsCondition?.length > 0
        ? cart?.lines?.some((line) =>
            line?.merchandise?.product?.collections?.some(
              (collection) => collection?.id && item.collectionsCondition.includes(collection.id)
            )
          )
        : true

    const meetsProductsCondition =
      item?.productsCondition?.length > 0
        ? cart?.lines?.some(
            (line) => line?.merchandise?.product?.id && item.productsCondition.includes(line.merchandise.product.id)
          )
        : true

    return meetsPriceCondition && meetsCollectionsCondition && meetsProductsCondition
  })

  // Filters out cart lines containing free products
  const freeProductsInCartAdded = cart?.lines?.filter(
    (line) => getProductCustomAttributesValue(line.attributes, "IS_FREE_PRODUCT_IN_CART") === "true"
  )

  // Add isInCart property instead of removing the item
  const freeProductInCart = resultFilteredData?.map((item) => {
    const isInCart = freeProductsInCartAdded?.some(
      (line) => item.productVariant?.id === line?.merchandise?.id // Adjust this if necessary
    )
    return {
      ...item,
      isInCart, // Adds isInCart: true | false
    }
  })

  // Sorts final results by rankingCondition and returns filtered products
  return freeProductInCart?.sort((a, b) => a.rankingCondition - b.rankingCondition) ?? []
}
