import type { Nullish } from "~/@types/generics"
import { getProductMetafieldValue, PRODUCT_CUSTOM_ATTRIBUTES } from "~/lib/shopify/constants"
import type { TVariant } from "~/lib/shopify/serializers/serialize-variants"
import { getEmbroideryValueWithCase } from "~/components/ui/Product/ProductHeader/PanelEmbroidery"
import type { FONTS_DB } from "~/components/ui/Product/ProductHeader/PanelEmbroidery/EmbroideryForm/EmbroideryFontSelector/constants"
import { EMBROIDERY_NONE_PICTO } from "~/components/ui/Product/ProductHeader/PanelEmbroidery/EmbroideryForm/utils"

type EmbroideryData = {
  firstEmbroidery?: string
  firstPicto: string
  secondEmbroidery?: string
  secondPicto: string
  emp: string
  color: string
  font: string
}

export function findEmbroidableVariantSKU(variant: Nullish<TVariant>) {
  if (!variant) {
    return null
  }

  if (variant?.components && variant?.components?.length > 0) {
    const foundBundleEmbroidableItem = variant?.components?.find((component) =>
      getProductMetafieldValue(component.product?.metafields, "EMBROIDERY")
    )

    if (foundBundleEmbroidableItem && foundBundleEmbroidableItem?.sku) {
      return foundBundleEmbroidableItem.sku
    }
  }

  return variant?.sku
}

function getCurrentEmbroideryData({
  embroidableSKU,
  firstEmbroidery,
  firstPicto,
  secondEmbroidery,
  secondPicto,
  emp,
  color,
  font,
}: {
  embroidableSKU: string
} & EmbroideryData) {
  return {
    ...(firstEmbroidery
      ? {
          [PRODUCT_CUSTOM_ATTRIBUTES.EMBROIDERY_TEXT1]: getEmbroideryValueWithCase(
            firstEmbroidery,
            font as keyof typeof FONTS_DB
          ),
        }
      : {}),
    [PRODUCT_CUSTOM_ATTRIBUTES.EMBROIDERY_PICTO1]: firstPicto,
    ...(secondEmbroidery
      ? {
          [PRODUCT_CUSTOM_ATTRIBUTES.EMBROIDERY_TEXT2]: getEmbroideryValueWithCase(
            secondEmbroidery,
            font as keyof typeof FONTS_DB
          ),
        }
      : {}),
    [PRODUCT_CUSTOM_ATTRIBUTES.EMBROIDERY_PICTO2]: secondPicto,
    ...(firstEmbroidery || firstPicto !== EMBROIDERY_NONE_PICTO
      ? { [PRODUCT_CUSTOM_ATTRIBUTES.EMBROIDERY_COLOR1]: color }
      : {}),
    ...(secondEmbroidery || secondPicto !== EMBROIDERY_NONE_PICTO
      ? { [PRODUCT_CUSTOM_ATTRIBUTES.EMBROIDERY_COLOR2]: color }
      : {}),
    [PRODUCT_CUSTOM_ATTRIBUTES.EMBROIDERY_FONT]: font,
    [PRODUCT_CUSTOM_ATTRIBUTES.EMBROIDERY_EMP]: emp,
    sku: embroidableSKU,
  }
}

export function getEmbroideryCustomAttributeData({
  embroideryDataAttribute,
  embroidableVariantSKUAttribute,
}: {
  embroideryDataAttribute: string
  embroidableVariantSKUAttribute: string
}) {
  const parsedDataAttributes = (JSON.parse(embroideryDataAttribute) as EmbroideryData) ?? null
  const parsedSKUAttributes = embroidableVariantSKUAttribute

  if (!parsedDataAttributes) {
    return null
  }

  const embroideryData = getCurrentEmbroideryData({ ...parsedDataAttributes, embroidableSKU: parsedSKUAttributes })

  return embroideryData
}
