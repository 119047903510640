import type { GID } from "@unlikelystudio/commerce-connector"

import type { Nullish, PropsWithClassName } from "~/@types/generics"
import { useLocale } from "~/lib/i18n/hooks/useLocale"
import type { useShopTheLookAddToCart } from "~/components/ui/Product/ShopTheLookCard/hooks/useShopTheLookAddToCart"
import RoundedCta, { type RoundedCtaProps } from "~/components/ui/RoundedCta"
import Icon from "~/components/abstracts/Icon"
import { PopinBackInStock } from "~/components/globals/PopinBackInStock"
import type { TPopinBackInStock } from "~/components/globals/PopinBackInStock/_data/serializer"
import { useTranslate } from "~/providers/I18nProvider/hooks/useTranslate"
import { usePopin } from "~/managers/PopinManager"

import * as css from "./styles.css"

export type AddToCartButtonProps = PropsWithClassName<{
  disabled: boolean
  price: Nullish<string>
  onClick?(): void
  isOutOfStock: boolean
  isLoading?: boolean
  variantId: Nullish<GID>
  productName: Nullish<string>
  popinBackInStock?: Nullish<TPopinBackInStock>
  outOfStockLabel?: string
  status?: ReturnType<typeof useShopTheLookAddToCart>[`status`]
  hasStockAlert?: boolean
}> &
  RoundedCtaProps

function AddToCartButton({
  className,
  disabled = false,
  price,
  isOutOfStock,
  isLoading = false,
  theme,
  children,
  onClick,
  variantId,
  popinBackInStock,
  productName,
  outOfStockLabel,
  status,
  hasStockAlert = true,
  ...rest
}: AddToCartButtonProps) {
  const t = useTranslate()
  const { add } = usePopin()

  // TODO TEMP :
  // The whole isBundle conditions are for Klaviyo, we need to do this temporarly to avoid getting the stock alert

  // TODO DELETE THIS CUSTOM CONDITION
  const locale = useLocale()
  const isNL = locale === "nl-nl"
  // END TODO DELETE THIS CUSTOM CONDITION

  const computedOutOfStockLabel = outOfStockLabel
    ? outOfStockLabel
    : isNL
    ? t("product_out_of_stock")
    : isOutOfStock && !hasStockAlert
    ? t("product_bundle_variant_out_of_stock")
    : t("product_out_of_stock")

  return (
    <RoundedCta
      size="large"
      className={className}
      isLoading={isLoading}
      theme={theme ?? status === "success" ? "grey-dark" : "blue-deep"}
      disabled={
        // TODO DELETE THIS CUSTOM CONDITION   (isNL && isOutOfStock) ||
        disabled ||
        (isNL && isOutOfStock) ||
        (isOutOfStock && !popinBackInStock?.form) ||
        (isOutOfStock && !hasStockAlert)
      }
      onClick={(event) => {
        if (isOutOfStock) {
          event.preventDefault()

          // TODO DELETE THIS CUSTOM CONDITION =>  !isNL &&
          !isNL && popinBackInStock?.form && variantId && hasStockAlert
            ? add(<PopinBackInStock form={popinBackInStock.form} variantId={variantId} productName={productName} />)
            : null
          return
        }
        onClick?.()
      }}
      {...rest}
      data-comp="UI/AddToCartButton"
    >
      <>
        {children ? (
          children
        ) : isOutOfStock ? (
          computedOutOfStockLabel
        ) : status === "success" ? (
          <span className={css.span}>
            {t("product_added_to_cart")}
            <Icon className={css.icon} name="Tick2" theme="white" />
          </span>
        ) : (
          <>
            {t("product_add_to_cart")}
            {price && <span className={css.price}>{price}</span>}
          </>
        )}
      </>
    </RoundedCta>
  )
}

export { AddToCartButton }
