"use client"

import clsx from "clsx"

import type { GID } from "@unlikelystudio/commerce-connector"

import type { Nullish } from "~/@types/generics"
import { PRODUCT_CUSTOM_ATTRIBUTES } from "~/lib/shopify/constants"
import { useAddToCart } from "~/lib/shopify/hooks/useAddToCart"
import type { TVariant } from "~/lib/shopify/serializers/serialize-variants"
import type { UnlikelyCartLineItemInput } from "~/lib/shopify/types"
import useGetCart from "~/hooks/cart/useGetCart"
import useBreakpoint from "~/hooks/useBreakpoint"
import { useLoadFont } from "~/hooks/useLoadFont"
import { HtmlRichText } from "~/components/ui/HtmlRichText"
import { PanelHeader } from "~/components/ui/PanelHeader"
import type { TProductHeaderWithEmbroidery } from "~/components/ui/Product/ProductHeader/_data/utils/is-product-header-with-embroidery"
import {
  EmbroideryForm,
  type onSubmitParams,
} from "~/components/ui/Product/ProductHeader/PanelEmbroidery/EmbroideryForm"
import type { FONTS_DB } from "~/components/ui/Product/ProductHeader/PanelEmbroidery/EmbroideryForm/EmbroideryFontSelector/constants"
import { EMBROIDERY_NONE_PICTO } from "~/components/ui/Product/ProductHeader/PanelEmbroidery/EmbroideryForm/utils"
import { PanelEmbroiderySliderImages } from "~/components/ui/Product/ProductHeader/PanelEmbroidery/SliderImages"
import { findEmbroidableVariantSKU } from "~/components/ui/Product/ProductHeader/PanelEmbroidery/utils/get-embroidery-custom-attribute"
import { Stack } from "~/components/abstracts/Stack"
import { Cart } from "~/components/globals/Cart"
import { useCartState } from "~/components/globals/Cart/useCartState"
import { serializeItemIdFromProductInfos, type CartLineItemPayload } from "~/providers/GTMTrackingProvider/constants"
import { Panel, usePanel } from "~/managers/PanelManager"

import { sprinkles } from "~/styles/sprinkles.css"
import { grid, text } from "~/styles/variants"

import * as css from "./styles.css"

type PanelEmbroideryProps = {
  currentVariant: Nullish<TVariant>
  productHeader: TProductHeaderWithEmbroidery
  productLineItem: UnlikelyCartLineItemInput
}

export function PanelEmbroidery({ currentVariant, productHeader, productLineItem }: PanelEmbroideryProps) {
  useLoadFont("lie to me", "lie_to_me", "woff2", 10)
  useLoadFont("handle", "handle", "woff2", 10)

  const { cart } = useGetCart()
  const setCurrentState = useCartState()[1]

  const { removeCurrent, add } = usePanel()

  const closePanel = () => {
    removeCurrent()
  }

  const { mutateAsync: addCartLinesAsync, isLoading } = useAddToCart()

  const isMobile = useBreakpoint("md")

  const { embroidery, title, globalProduct } = productHeader

  async function onSubmit({ formData, currentEmbroideryVariant, currentEmbroideryProductId }: onSubmitParams) {
    const firstEmbroidery = formData.embroideries[0]?.value
    const secondEmbroidery = formData.embroideries[1]?.value
    const firstPicto = formData.embroideries[0]?.picto ?? EMBROIDERY_NONE_PICTO
    const secondPicto = formData.embroideries[1]?.picto ?? EMBROIDERY_NONE_PICTO
    const placementEmbroidery = formData.placement

    const uniqEmbroideryAttributes = JSON.stringify({
      firstEmbroidery,
      secondEmbroidery,
      firstPicto,
      secondPicto,
      color: formData.color,
      font: formData.font,
      embroideryVariantId: currentEmbroideryVariant.id,
      productId: productHeader.id,
      variantId: currentVariant?.id,
      emp: placementEmbroidery,
    })

    const embroidableProductVariantSKU = findEmbroidableVariantSKU(currentVariant)

    addCartLinesAsync(
      [
        {
          ...productLineItem,
          attributes: [
            ...(productLineItem.attributes ?? []),
            ...(firstEmbroidery
              ? [
                  {
                    key: PRODUCT_CUSTOM_ATTRIBUTES.EMBROIDERY_TEXT1,
                    value: getEmbroideryValueWithCase(firstEmbroidery, formData.font),
                  },
                ]
              : []),
            {
              key: PRODUCT_CUSTOM_ATTRIBUTES.EMBROIDERY_PICTO1,
              value: firstPicto,
            },
            ...(secondEmbroidery
              ? [
                  {
                    key: PRODUCT_CUSTOM_ATTRIBUTES.EMBROIDERY_TEXT2,
                    value: getEmbroideryValueWithCase(secondEmbroidery, formData.font),
                  },
                ]
              : []),
            {
              key: PRODUCT_CUSTOM_ATTRIBUTES.EMBROIDERY_PICTO2,
              value: secondPicto,
            },
            // Keep color and font under text1 and text2 because they are display after text1 and text2 inside the cart
            ...(firstEmbroidery || firstPicto !== EMBROIDERY_NONE_PICTO
              ? [{ key: PRODUCT_CUSTOM_ATTRIBUTES.EMBROIDERY_COLOR1, value: formData.color }]
              : []),
            ...(secondEmbroidery || secondPicto !== EMBROIDERY_NONE_PICTO
              ? [{ key: PRODUCT_CUSTOM_ATTRIBUTES.EMBROIDERY_COLOR2, value: formData.color }]
              : []),
            { key: PRODUCT_CUSTOM_ATTRIBUTES.EMBROIDERY_FONT, value: formData.font },
            { key: PRODUCT_CUSTOM_ATTRIBUTES.EMBROIDERY_EMP, value: placementEmbroidery },
            {
              key: PRODUCT_CUSTOM_ATTRIBUTES.UNIQ_EMBROIDERY_ATTRIBUTES,
              value: uniqEmbroideryAttributes,
            },
          ],
        },
        {
          quantity: 1,
          merchandiseId: currentEmbroideryVariant.id ?? "",
          attributes: [
            { key: PRODUCT_CUSTOM_ATTRIBUTES.IS_EMBROIDERY_PRODUCT, value: "true" },
            {
              key: PRODUCT_CUSTOM_ATTRIBUTES.EMBROIDERY_EMBRODABLE_PRODUCT_VARIANT_SKU,
              value: embroidableProductVariantSKU ?? "",
            },
            {
              key: PRODUCT_CUSTOM_ATTRIBUTES.UNIQ_EMBROIDERY_ATTRIBUTES,
              value: uniqEmbroideryAttributes,
            },

            {
              key: PRODUCT_CUSTOM_ATTRIBUTES.TRACKING_DATA,
              value: JSON.stringify({
                item_variant: currentEmbroideryVariant.title ?? "",
                item_id: currentEmbroideryVariant.id
                  ? serializeItemIdFromProductInfos(currentEmbroideryProductId, currentEmbroideryVariant.id as GID)
                  : "",
                item_name: currentEmbroideryVariant?.title ?? "",
                item_sku: currentEmbroideryVariant?.sku ?? "",
                item_url: "",
                item_image_url: currentEmbroideryVariant?.image?.[0]?.src ?? "",
                price: currentEmbroideryVariant?.unserializedPrice?.amount ?? null,
                compare_at_price: currentEmbroideryVariant?.unserializedCompareAtPrice?.amount ?? null,
                currency: currentEmbroideryVariant?.unserializedPrice?.currencyCode ?? "",
                quantity: 1,
                item_category: "",
                // Setting +2 here because the first item is the product itself and the second is the embroidery product
                index: cart && cart.lines ? cart.lines.length + 1 : 1,
              } satisfies CartLineItemPayload),
            },
          ],
        },
      ],
      {
        onSuccess() {
          closePanel()
          setCurrentState(isMobile ? "toaster" : "cart")
          add(<Cart />)
        },
      }
    )
  }

  return (
    <Panel bgColor="white">
      <div className={sprinkles({ width: "100%", overflowY: { mobile: "auto", tablet: "initial" } })}>
        <PanelHeader
          title={embroidery.title}
          ariaCloseLabel="aria_panel_embroidery_close"
          sprinklesCss={{ pX: 15 }}
          hideFrom="tablet"
        />
        <div
          className={clsx(grid({ type: "fluid" }), sprinkles({ height: { tablet: "100%" }, alignItems: "flex-start" }))}
        >
          <PanelEmbroiderySliderImages
            images={embroidery.images}
            className={css.SliderImages}
            sliderItemClassName={css.SliderItem}
          />
          <Stack
            direction="column"
            gap={20}
            className={clsx(
              css.PanelEmbroidery,
              sprinkles({
                pB: { tablet: 30 },
                pX: { tablet: 30, desktop: 60 },
              })
            )}
          >
            <PanelHeader
              title={embroidery.title}
              showFrom="tablet"
              ariaCloseLabel="aria_panel_embroidery_close"
              sprinklesCss={{
                mT: { tablet: 30 },
                pX: { mobile: 15, tablet: 0 },
              }}
            />
            <Stack direction="column" gap={20} sprinklesCss={{ mY: { mobile: 20, tablet: 0 } }}>
              {embroidery.description && (
                <div
                  className={text({
                    design: "riposte-14",
                    lineHeight: 1.45,
                    letterSpacing: 0.2,
                    color: "blue-light-txt",
                  })}
                >
                  <HtmlRichText>{embroidery.description}</HtmlRichText>
                </div>
              )}
              <EmbroideryForm
                embroidery={embroidery}
                productTitle={title}
                isLoading={isLoading}
                currentVariant={currentVariant}
                productHeaderType={productHeader.type}
                embroideryPictosEnabled={globalProduct?.embroidery.pictosEnabled ?? false}
                embroideryPictos={globalProduct?.embroidery.pictos ?? []}
                embroideryDynamicPlaceholder={globalProduct?.embroidery.dynamicPlaceholderText ?? []}
                productName={productHeader?.title ?? null}
                onSubmit={onSubmit}
              />
              {embroidery.footerDescription && (
                <div className={text({ design: "riposte-13", lineHeight: 1.5, color: "blue-light-txt" })}>
                  <HtmlRichText>{embroidery.footerDescription}</HtmlRichText>
                </div>
              )}
            </Stack>
          </Stack>
        </div>
      </div>
    </Panel>
  )
}

export function getEmbroideryValueWithCase(value: string, font: keyof typeof FONTS_DB) {
  return font === "mano" ? value.toLowerCase() : value.toUpperCase()
}
