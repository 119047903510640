"use client"

import clsx from "clsx"

import type { Nullish, PropsWithClassName } from "~/@types/generics"
import { Image } from "~/components/ui/Image"
import { Link } from "~/components/ui/Link"
import type { TColorWithDotImage } from "~/components/ui/Product/ProductHeader/ColorsSelector"
import { Stack } from "~/components/abstracts/Stack"
import { useTranslate } from "~/providers/I18nProvider/hooks/useTranslate"

import * as css from "./styles.css"

type EmbroideryColorSelectorProps = PropsWithClassName<{
  currentColor: Nullish<string>
  colorsWithDotImage?: TColorWithDotImage[]
  onChange?: (currentColor: string) => void
}>

export function EmbroideryColorSelector({
  className,
  currentColor,
  colorsWithDotImage,
  onChange,
}: EmbroideryColorSelectorProps) {
  const t = useTranslate()

  return (
    <div
      className={clsx(className, css.EmbroideryColorSelector)}
      data-comp="UI/Product/ProductHeader/PanelEmbroidery/EmbroideryForm/EmbroideryColorSelector"
    >
      <Stack
        className={css.ProductColorsWrapper}
        gap={15}
        sprinklesCss={{ justifyContent: "center", flexFlow: "row wrap" }}
      >
        {colorsWithDotImage?.map((color, index) => {
          const isActive = color.value === currentColor
          const isAvailableForSale = color.availableForSale ?? true

          return (
            <Link
              key={`selector_color_${index}`}
              aria-label={t("aria_color_switch", { color: color.value })}
              className={clsx(className, css.ProductColorsSelectorItem, {
                [css.buttonVariants.active]: isActive,
                [css.buttonVariants.outOfStock]: !isAvailableForSale,
              })}
              disabled={isActive}
              {...color.link}
              onClick={() => onChange?.(color.value)}
            >
              {color.image && <Image {...color.image} />}
            </Link>
          )
        })}
      </Stack>
    </div>
  )
}
