import type { UnlikelyProduct } from "@unlikelystudio/commerce-connector"

import type { InferReturn } from "~/@types/generics"
import { getAssociatedProductColors } from "~/components/ui/Product/ProductHeader/_data/get-associated-product-colors"
import { serializeProductColor } from "~/components/ui/Product/ProductHeader/_data/serialize-product-color"
import { getProductTitle } from "~/components/ui/Product/utils/get-product-title"
import { PRODUCT_CUSTOM_ATTRIBUTES, type ColorCustomAttribute } from "~/lib/shopify/constants"
import { serializeVariants, type TVariant } from "~/lib/shopify/serializers/serialize-variants"
import type { UnlikelyCartLineItemInput } from "~/lib/shopify/types"
import { serializeItemIdFromProductInfos, type CartLineItemPayload } from "~/providers/GTMTrackingProvider/constants"
import getAlternateUrl from "~/utils/alternate-url"

export type TCrossSellProductCard = InferReturn<typeof serializeCrossSellProductCard>

export async function serializeCrossSellProductCard(product: UnlikelyProduct, locale: string) {
  const [currentColor, associatedProductsColor] = await Promise.all([
    serializeProductColor(product, locale),
    getAssociatedProductColors(product, locale),
  ])

  return {
    product,
    id: product.id,
    title: getProductTitle(product),
    images: product.images,
    colorsWithDotImage: associatedProductsColor ?? [],
    defaultColor: currentColor,
    variants: serializeVariants(product, product?.variants, locale),
  }
}

export function getCrossSellProductLineItem({
  quantity = 1,
  product,
  variant,
  dotColor,
  pathname,
  index,
  additionalAttributes = [],
  additionalTrackingData = {},
}: {
  quantity?: number
  product: UnlikelyProduct
  variant: TVariant
  dotColor: ColorCustomAttribute
  pathname: string
  index: number
  additionalAttributes?: UnlikelyCartLineItemInput["attributes"]
  additionalTrackingData?: Partial<CartLineItemPayload>
}) {
  const collection = product.collections.at(-1)?.title

  return {
    quantity,
    merchandiseId: variant?.id ? variant.id : "",
    attributes: [
      { key: PRODUCT_CUSTOM_ATTRIBUTES.PRODUCT_ID, value: product?.id ?? "" },
      { key: PRODUCT_CUSTOM_ATTRIBUTES.PRODUCT_COLOR, value: JSON.stringify(dotColor) },
      { key: PRODUCT_CUSTOM_ATTRIBUTES.PRODUCT_URL, value: getAlternateUrl(pathname) ?? "" },
      {
        key: PRODUCT_CUSTOM_ATTRIBUTES.TRACKING_DATA,
        value: JSON.stringify({
          item_variant: variant?.title ?? "",
          item_id: product?.id && variant?.id ? serializeItemIdFromProductInfos(product?.id, variant?.id) : "",
          item_name: product?.title ?? "",
          item_sku: variant?.sku ?? "",
          item_url: getAlternateUrl(pathname) ?? "",
          item_image_url: variant?.image?.[0]?.src ?? "",
          price: variant?.unserializedPrice?.amount ?? null,
          compare_at_price: variant?.unserializedCompareAtPrice?.amount ?? null,
          currency: variant?.unserializedPrice?.currencyCode ?? "",
          quantity,
          item_category: collection ?? "",
          index,
          ...additionalTrackingData,
        } satisfies CartLineItemPayload),
      },
      // Used inside serializeCartPayload
      ...(collection ? [{ key: PRODUCT_CUSTOM_ATTRIBUTES.COLLECTION, value: collection }] : []),
      ...additionalAttributes,
    ],
  } satisfies UnlikelyCartLineItemInput
}
