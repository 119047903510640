import type { AllNullable, GID } from "@unlikelystudio/commerce-connector"

import type { Nullish } from "~/@types/generics"
import type { TVariant } from "~/lib/shopify/serializers/serialize-variants"
import { EVERY_OPTIONS_WITHOUT_COLOR } from "~/components/ui/Product/ProductHeader/_data/constants"
import { getVariantConfort } from "~/components/ui/Product/ProductHeader/_data/utils/get-variant-confort"
import { getVariantSize } from "~/components/ui/Product/ProductHeader/_data/utils/get-variant-size"

export function getOptionsWithoutColor(variants: Nullish<TVariant[]>) {
  return variants?.map(getOptionWithoutColor) ?? null
}

export type VariantOptionWithoutColor = AllNullable<{
  value: string
  id: GID
  availableForSale: boolean
}>

export function getOptionWithoutColor(variant: TVariant): VariantOptionWithoutColor {
  const value =
    variant?.selectedOptions
      ?.reduce((acc, curr) => {
        if (curr.name && EVERY_OPTIONS_WITHOUT_COLOR.includes(curr.name) && curr?.value) acc.push(curr.value)
        return acc
      }, [] as string[])
      ?.join(" / ") ?? null

  return {
    value,
    id: variant.id,
    availableForSale: variant.availableForSale,
  }
}

//Only for single product with select selector when only Confort option

export type VariantOptionWithoutColorForSingleProduct = AllNullable<{
  size: string
  confort: string
  id: GID
  availableForSale: boolean
}>

export function getUniqOptions(
  options: AllNullable<VariantOptionWithoutColorForSingleProduct>[],
  optionName: "size" | "confort"
) {
  const allOptions = options.map((option) => option[optionName])
  return [...new Set(allOptions)]
}

export function getUniqOptionsValues(
  options: AllNullable<VariantOptionWithoutColorForSingleProduct>[],
  optionName: "size" | "confort"
) {
  return options.reduce((acc, curr) => {
    const value = curr[optionName]
    if (!value) return acc
    const availableForSale = curr.availableForSale ?? false
    const f = acc.find((a) => a.value === value)
    if (!f) {
      acc.push({ value, availableForSale })
    } else {
      f.availableForSale = f.availableForSale && availableForSale
    }

    return acc
  }, [] as { value: string; availableForSale: boolean }[])
}

export function getOptionsForSingleProduct(variants: Nullish<TVariant[]>) {
  return variants?.map(getOptionForSingleProduct) ?? null
}

export function getOptionForSingleProduct(variant: TVariant): VariantOptionWithoutColorForSingleProduct {
  const size = getVariantSize(variant)
  const confort = getVariantConfort(variant)

  return {
    size,
    confort,
    id: variant.id,
    availableForSale: variant.availableForSale,
  }
}
