"use client"

import clsx from "clsx"

import type { PropsWithClassName } from "~/@types/generics"
import { useSerializePrice } from "~/data/price/hooks"
import useGetCart from "~/hooks/cart/useGetCart"
import { useGetMultipassCheckoutUrl } from "~/hooks/checkout/useGetMultipassCheckoutUrl"
import RoundedCta from "~/components/ui/RoundedCta"
import Reassurance from "~/components/globals/Cart/components/Reassurance"
import { useTranslate } from "~/providers/I18nProvider/hooks/useTranslate"
import type { TCart } from "~/managers/CartManager/_data/serializer"
import { usePanel } from "~/managers/PanelManager"

import * as css from "./styles.css"

export type BottomCartProps = PropsWithClassName<{
  emptyCart: boolean
  emptyCartLabelButton: NonNullable<TCart>["emptyCart"]["emptyCartLabelButton"]
  reassurance: NonNullable<TCart>["reassurance"]
  isLoading: boolean
}>

function BottomCart({ className, emptyCart, emptyCartLabelButton, reassurance, isLoading }: BottomCartProps) {
  const { removeCurrent } = usePanel()
  const t = useTranslate()
  const { totalPrice, currencyCode } = useGetCart()

  const { mutate: handleCartConfirmation, isLoading: isMultipassLoading } = useGetMultipassCheckoutUrl()

  const handleCartValidation = () => {
    handleCartConfirmation()
  }

  const price = useSerializePrice({
    currencyCode,
    amount: totalPrice,
  })

  const onPanelRemove = () => {
    removeCurrent()
  }

  return (
    <div className={clsx(className, css.BottomCart, { [css.BottomCartFilled]: !emptyCart })}>
      {emptyCart ? (
        emptyCartLabelButton ? (
          <RoundedCta size="large" width="full" theme="blue-deep" type="primary" onClick={onPanelRemove}>
            {emptyCartLabelButton}
          </RoundedCta>
        ) : null
      ) : (
        <div>
          <RoundedCta
            onClick={handleCartValidation}
            isLoading={isMultipassLoading || isLoading}
            className={css.fullCta}
            width="full"
            size="large"
            theme="blue-deep"
            type="primary"
          >
            {t("cart_validation_label")} - {price}
          </RoundedCta>
          {reassurance.reassuranceText && <Reassurance reassurance={reassurance} />}
        </div>
      )}
    </div>
  )
}

export default BottomCart
