import parse, { domToReact, type Element, type HTMLReactParserOptions } from "html-react-parser"

import type { Nullish } from "~/@types/generics"
import { Link } from "~/components/ui/Link"
import isInternalUrl from "~/utils/is-internal-url"

export interface HtmlRichTextProps {
  children: Nullish<string>
}

const options = {
  replace: (domNode) => {
    const domNodeElement = domNode as Element

    //find so contribution error in Shopify,
    //Find 2 url in one, so we catch it and return an empty url like an error
    const WRONG_URL_REGEX = /https:\/\/.*https:\/\/.*/

    //Shopify's translation add useless <a> without href and destroy the front
    const hasLinkHref =
      domNodeElement.attribs && Object.keys(domNodeElement.attribs).find((attr) => (attr === "href" ? true : false))

    if (domNodeElement.type === "tag" && domNodeElement.name === "a" && hasLinkHref && domNodeElement.attribs.href) {
      const { status, pathname } = isInternalUrl(domNodeElement.attribs.href)
      const isWrongUrlResult = Boolean(WRONG_URL_REGEX.exec(domNodeElement.attribs.href))

      return (
        status && (
          <Link href={isWrongUrlResult ? undefined : pathname.toLowerCase()}>
            {domToReact(domNodeElement.children, options)}
          </Link>
        )
      )
    }

    //Shopify's translation add useless <a> and destroy the front
    // replace the <a> useless by a <p>
    // add display inline to follow the style
    if (domNodeElement.type === "tag" && domNodeElement.name === "a" && !hasLinkHref) {
      return <p style={{ display: "inline" }}>{domToReact(domNodeElement.children)}</p>
    }
  },
} satisfies HTMLReactParserOptions

function HtmlRichText({ children }: HtmlRichTextProps) {
  if (!children) return null
  return <>{parse(children, options)}</>
}

export { HtmlRichText }
