import { Suspense, useEffect, useState } from "react"
import { zodResolver } from "@hookform/resolvers/zod"
import clsx from "clsx"
import { useFieldArray, useForm } from "react-hook-form"

import type { GID } from "@unlikelystudio/commerce-connector"

import type { Nullish } from "~/@types/generics"
import type { TVariant } from "~/lib/shopify/serializers/serialize-variants"
import { useSerializePrice } from "~/data/price/hooks"
import { AddToCartButton, type AddToCartButtonProps } from "~/components/ui/AddToCartButton"
import { Form, FormControl, FormError, FormField, FormItem, FormLabel } from "~/components/ui/Form"
import Input from "~/components/ui/Input"
import type { TEmbroidery } from "~/components/ui/Product/ProductHeader/_data/serialize-embroidery"
import type { TProductHeader } from "~/components/ui/Product/ProductHeader/_data/serializer"
import { getVariantColor } from "~/components/ui/Product/ProductHeader/_data/utils/get-variant-color"
import { getVariantSize } from "~/components/ui/Product/ProductHeader/_data/utils/get-variant-size"
import {
  getEmbroideryFormSchema,
  type TEmbroideryFormData,
} from "~/components/ui/Product/ProductHeader/PanelEmbroidery/EmbroideryForm/_data/schema"
import { EmbroideryColorSelector } from "~/components/ui/Product/ProductHeader/PanelEmbroidery/EmbroideryForm/EmbroideryColorSelector"
import {
  EmbroideryFontSelector,
  type FONTS,
} from "~/components/ui/Product/ProductHeader/PanelEmbroidery/EmbroideryForm/EmbroideryFontSelector"
import {
  EmbroideryPictoSelector,
  type EmbroideryPictosProps,
} from "~/components/ui/Product/ProductHeader/PanelEmbroidery/EmbroideryForm/EmbroideryPictoSelector"
import {
  EmbroideryPlacementSelector,
  type PLACEMENTS,
} from "~/components/ui/Product/ProductHeader/PanelEmbroidery/EmbroideryForm/EmbroideryPlacementSelector"
import { isValidEmplacementType } from "~/components/ui/Product/ProductHeader/PanelEmbroidery/EmbroideryForm/EmbroideryPlacementSelector/utils"
import { PanelEmbroideryFormItem } from "~/components/ui/Product/ProductHeader/PanelEmbroidery/EmbroideryForm/FormItem"
import { SummaryItem } from "~/components/ui/Product/ProductHeader/PanelEmbroidery/EmbroideryForm/SummaryItem"
import {
  EMBROIDERY_NONE_PICTO,
  getCurrentEmbroideryProduct,
} from "~/components/ui/Product/ProductHeader/PanelEmbroidery/EmbroideryForm/utils"
import { Stack } from "~/components/abstracts/Stack"
import { useTranslate } from "~/providers/I18nProvider/hooks/useTranslate"

import { sprinkles } from "~/styles/sprinkles.css"
import { text } from "~/styles/variants"

export type onSubmitParams = {
  formData: TEmbroideryFormData
  currentEmbroideryVariant: TVariant
  currentEmbroideryProductId: GID
}

type EmbroideryFormProps = {
  productTitle: Nullish<string>
  currentVariant: Nullish<TVariant>
  isLoading: boolean
  embroidery: NonNullable<TEmbroidery>
  productHeaderType: TProductHeader["type"]
  productName: AddToCartButtonProps["productName"]
  embroideryPictosEnabled: boolean
  embroideryPictos: EmbroideryPictosProps
  embroideryDynamicPlaceholder: {
    text1: string
    text2: string
  }[]
  onSubmit: ({ formData, currentEmbroideryVariant }: onSubmitParams) => void
}

const INTERVAL_TIMER = 3000
const INITIAL_FONT: (typeof FONTS)[number] = "bâton"
const INITIAL_PLACEMENT: (typeof PLACEMENTS)[number] = "none"

export function EmbroideryForm({
  productTitle,
  currentVariant,
  isLoading,
  embroidery,
  productHeaderType,
  productName,
  embroideryPictosEnabled,
  embroideryPictos,
  embroideryDynamicPlaceholder,
  onSubmit,
}: EmbroideryFormProps) {
  const [currentFont, setCurrentFont] = useState<(typeof FONTS)[number]>(INITIAL_FONT)
  const [currentPlacement, setCurrentPlacement] = useState<(typeof PLACEMENTS)[number]>(INITIAL_PLACEMENT)

  const lengthByPlacementType = findLengthByPlacementType(embroidery, currentPlacement)

  const embroideryLength = lengthByPlacementType ?? embroidery.length

  const filteredembroideryDynamicPlaceholder = embroideryDynamicPlaceholder.filter((placeholder) => {
    return placeholder.text1.length <= embroideryLength && placeholder.text2.length <= embroideryLength
  })

  const [currentDynamicPlaceholder, setCurrentDynamicPlaceholder] = useState(filteredembroideryDynamicPlaceholder[0])

  const t = useTranslate()
  const currentVariantSize = getVariantSize(currentVariant)

  const isSmallEmbroidery = embroidery.type === "small"
  const isDoubleEmbroidery = embroidery.type === "double"

  const schema = getEmbroideryFormSchema({ t, isDoubleEmbroidery, embroideryLength, currentFont })

  const form = useForm<TEmbroideryFormData>({
    defaultValues: {
      font: INITIAL_FONT,
      placement: INITIAL_PLACEMENT,
      color: embroidery.oneEmbroideryProduct?.colorsWithDotImage[0]?.value,
      embroideries: isDoubleEmbroidery
        ? [
            { value: "", picto: EMBROIDERY_NONE_PICTO },
            { value: "", picto: EMBROIDERY_NONE_PICTO },
          ]
        : [{ value: "", picto: EMBROIDERY_NONE_PICTO }],
      embroideryNotEmpty: null,
    },
    resolver: zodResolver(schema),
    mode: "onChange",
  })

  const { control, watch, resetField, setError, setValue } = form
  const { fields } = useFieldArray({
    control,
    name: "embroideries",
  })

  const currentEmbroideryProduct = getCurrentEmbroideryProduct(watch("embroideries"), embroidery)
  const initialPrice = useSerializePrice({
    amount: 0,
    currencyCode: embroidery.oneEmbroideryProduct?.unserializedPrice?.currencyCode,
  })

  const summaryPrice = currentEmbroideryProduct?.price ?? initialPrice

  const oneEmbroideryPrice = embroidery.oneEmbroideryProduct?.price

  useEffect(() => {
    const currentColorVariant = currentEmbroideryProduct?.variants.find(
      (variant) => getVariantColor(variant) === watch("color")
    )

    // After changing embroidery product, if the current color doesn't exist in the new product, we set the first color
    if (!currentColorVariant && currentEmbroideryProduct?.colorsWithDotImage?.[0]) {
      setValue("color", currentEmbroideryProduct.colorsWithDotImage[0]?.value)
    }
  }, [currentEmbroideryProduct?.id])

  const submitHandler = form.handleSubmit(async (data) => {
    if (!currentEmbroideryProduct) {
      setError("embroideryNotEmpty", { message: t("error_at_least_one_embroidery") })
      return
    }

    const currentEmbroideryVariant =
      currentEmbroideryProduct.variants.find((variant) => getVariantColor(variant) === data.color) ??
      currentEmbroideryProduct.variants[0]

    if (currentEmbroideryVariant && currentEmbroideryProduct.id) {
      onSubmit({ currentEmbroideryVariant, currentEmbroideryProductId: currentEmbroideryProduct.id, formData: data })
    }
  })

  const totalPrice = useSerializePrice({
    amount:
      (currentVariant?.unserializedPrice?.amount ?? 0) + (currentEmbroideryProduct?.unserializedPrice?.amount ?? 0),
    currencyCode: currentVariant?.unserializedPrice?.currencyCode,
  })

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentDynamicPlaceholder((prevPlaceholder) => {
        const currentIndex = prevPlaceholder ? filteredembroideryDynamicPlaceholder.indexOf(prevPlaceholder) : -1
        const nextIndex = (currentIndex + 1) % filteredembroideryDynamicPlaceholder.length
        return filteredembroideryDynamicPlaceholder[nextIndex]
      })
    }, INTERVAL_TIMER)

    // Nettoyer l'intervalle lors de la désactivation du composant
    return () => clearInterval(intervalId)
  }, [])

  const isAnyEmbroideryFilled = watch("embroideries").some((embroidery) => embroidery.value)

  return (
    <Form {...form}>
      <Stack asChild direction="column" gap={20}>
        <form onSubmit={submitHandler}>
          <div>
            <FormField
              control={form.control}
              name="font"
              render={({ field }) => {
                return (
                  <FormItem>
                    <PanelEmbroideryFormItem title={t("embroidery_font_title")} wrapperColumnResponsive>
                      <FormControl>
                        <EmbroideryFontSelector
                          currentFont={field.value}
                          onChange={(newValue) => {
                            setCurrentFont(newValue)
                            field.onChange(newValue)
                          }}
                        />
                      </FormControl>
                    </PanelEmbroideryFormItem>
                  </FormItem>
                )
              }}
            />

            <FormField
              control={form.control}
              name="placement"
              render={({ field }) =>
                isValidEmplacementType(embroidery.embroideryPlacementType) ? (
                  <FormItem>
                    <PanelEmbroideryFormItem title={t("embroidery_placement_title")} wrapperColumnResponsive>
                      <FormControl>
                        <EmbroideryPlacementSelector
                          currentPlacement={field.value}
                          onChange={(newValue) => {
                            setCurrentPlacement(newValue)
                            field.onChange(newValue)
                          }}
                          embroideryPlacementType={embroidery.embroideryPlacementType}
                        />
                      </FormControl>
                    </PanelEmbroideryFormItem>
                  </FormItem>
                ) : (
                  <></>
                )
              }
            />

            <FormField
              control={form.control}
              name="color"
              render={({ field }) => (
                <FormItem>
                  <PanelEmbroideryFormItem title={t("embroidery_color_title")} subtitle={field.value}>
                    <FormControl>
                      <Suspense>
                        <EmbroideryColorSelector
                          colorsWithDotImage={
                            currentEmbroideryProduct?.colorsWithDotImage ??
                            embroidery.oneEmbroideryProduct?.colorsWithDotImage ??
                            []
                          }
                          currentColor={field.value}
                          onChange={field.onChange}
                        />
                      </Suspense>
                    </FormControl>
                  </PanelEmbroideryFormItem>
                </FormItem>
              )}
            />

            {fields.map((data, index) => (
              <>
                <FormField
                  key={`${data.id}-${index}`}
                  control={form.control}
                  name={`embroideries.${index}.value`}
                  render={({ field }) => {
                    return (
                      <FormItem>
                        <PanelEmbroideryFormItem
                          suptitle={embroidery.embroideryTitle}
                          title={
                            <FormLabel>
                              {t(isSmallEmbroidery ? "embroidery_small_title" : "embroidery_long_title")}
                            </FormLabel>
                          }
                          subtitle={
                            isDoubleEmbroidery ? (
                              <div>{t("embroidery_constraint", { length: embroideryLength })}</div>
                            ) : (
                              t("embroidery_constraint", { length: embroideryLength })
                            )
                          }
                          count={isDoubleEmbroidery ? index + 1 : 0}
                          oneEmbroideryPrice={oneEmbroideryPrice}
                        >
                          <Stack direction="column" gap={2}>
                            <FormControl>
                              <Input
                                variant="embroidery"
                                textStyle={`embroidery-${watch("font")}`}
                                maxLength={embroideryLength}
                                placeholder={
                                  currentDynamicPlaceholder && !isAnyEmbroideryFilled
                                    ? Object.values(currentDynamicPlaceholder)?.[index]
                                    : undefined
                                }
                                onChange={(event) => {
                                  field.onChange(event)
                                  resetField("embroideryNotEmpty")
                                }}
                                inputClassName={sprinkles({ borderRadius: 0 })}
                              />
                            </FormControl>
                            <FormError />
                          </Stack>
                        </PanelEmbroideryFormItem>
                      </FormItem>
                    )
                  }}
                />
                {embroideryPictosEnabled && (
                  <FormField
                    control={form.control}
                    name={`embroideries.${index}.picto`}
                    render={({ field }) => {
                      const currentPictoText =
                        embroideryPictos?.find((picto) => picto.value === field.value)?.text ?? field.value
                      return (
                        <FormItem>
                          <PanelEmbroideryFormItem
                            title={t("embroidery_pictos")}
                            subtitle={
                              field.value === EMBROIDERY_NONE_PICTO || !field.value
                                ? t("embroidery_picto_none_selected")
                                : currentPictoText
                            }
                            sprinklesCss={{
                              borderTopWidth: 0,
                            }}
                          >
                            <EmbroideryPictoSelector
                              currentPicto={field.value}
                              onChange={field.onChange}
                              embroideryPictos={embroideryPictos}
                            />
                          </PanelEmbroideryFormItem>
                        </FormItem>
                      )
                    }}
                  />
                )}
              </>
            ))}
            <FormField
              control={form.control}
              name="embroideryNotEmpty"
              render={() => (
                <FormItem>
                  <FormError className={sprinkles({ mB: 20 })} />
                </FormItem>
              )}
            />
          </div>
          <div>
            <p
              className={clsx(
                text({
                  design: "spezia-18",
                  transform: "uppercase",
                  lineHeight: 1,
                  letterSpacing: 0.4,
                }),
                sprinkles({ mB: 20 })
              )}
            >
              {t("embroidery_summary_title")}
            </p>
            {productTitle && (
              <SummaryItem title={productTitle} item={productHeaderType === "product" ? currentVariantSize : null} />
            )}
            {summaryPrice && <SummaryItem title={t("embroidery")} item={summaryPrice} />}
          </div>
          {currentVariant?.id && (
            <AddToCartButton
              htmlType="submit"
              isLoading={isLoading}
              isOutOfStock={!currentVariant?.availableForSale}
              width="full"
              disabled={!totalPrice}
              price={totalPrice}
              variantId={currentVariant.id}
              productName={`${productName} - ${currentVariant?.title}`}
            />
          )}
        </form>
      </Stack>
    </Form>
  )
}

function findLengthByPlacementType(
  embroidery: NonNullable<TEmbroidery>,
  currentPlacement: (typeof PLACEMENTS)[number]
) {
  if (
    isValidEmplacementType(embroidery.embroideryPlacementType) &&
    embroidery.lengthByPlacementType?.emplacementType === embroidery.embroideryPlacementType &&
    embroidery.lengthByPlacementType.placement === currentPlacement
  ) {
    return embroidery.lengthByPlacementType.length
  }
}
