import type { StyleRule } from "@vanilla-extract/css"

import { theme } from "~/styles/theme.css"

export const FONTS_DB = {
  bâton: {
    style: {
      fontFamily: theme.fontFamilies.arial,
      textTransform: theme.textTransform.uppercase,
      fontWeight: theme.fontWeights.regular,
      color: theme.colors["blue-med"],
      fontSize: theme.fontSizes[16],
      "@media": {
        ["screen and (min-width: 760px)"]: {
          fontSize: theme.fontSizes[14],
        },
      },
    } satisfies StyleRule,
  },
  rétro: {
    style: {
      fontFamily: theme.fontFamilies.handle,
      textTransform: theme.textTransform.uppercase,
      fontWeight: theme.fontWeights.regular,
      fontSize: theme.fontSizes[16],
      color: theme.colors["blue-med"],
      "@media": {
        ["screen and (min-width: 760px)"]: {
          fontSize: theme.fontSizes[14],
        },
      },
    } satisfies StyleRule,
  },
  mano: {
    style: {
      fontFamily: theme.fontFamilies.lie_to_me,
      textTransform: theme.textTransform.lowercase,
      fontWeight: theme.fontWeights.regular,
      fontSize: theme.fontSizes[16],
      "@media": {
        ["screen and (min-width: 760px)"]: {
          fontSize: theme.fontSizes[14],
        },
      },
      color: theme.colors["blue-med"],
    } satisfies StyleRule,
  },
} as const
