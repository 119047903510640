import type { UnlikelyProduct, UnlikelyVariant } from "@unlikelystudio/commerce-connector"

import type { InferReturn, Nullish } from "~/@types/generics"
import { getMarket } from "~/lib/i18n/utils/get-i18n"
import { getProductMetafieldValue, getVariantMetafieldValue } from "~/lib/shopify/constants"
import { parseShopifyReferencesFromMetatield } from "~/lib/shopify/utils/parse-references-from-metafields"
import { parseTextsFromMetafield } from "~/lib/shopify/utils/parse-texts-from-metafields"
import serializePrice from "~/data/price/serializer"
import { serializeShopifyImages } from "~/components/ui/Image/_data/serializer"

export type TVariant = InferReturn<typeof serializeVariants>[number]

export function serializeVariants(
  product: Nullish<UnlikelyProduct>,
  variants: Nullish<UnlikelyVariant[]>,
  locale: Nullish<string>
) {
  if (!variants || variants.length === 0 || !product) {
    return []
  }

  // Hidden variants
  const hiddenVariantsForProduct = getProductMetafieldValue(product.metafields, "HIDE_VARIANTS_IN_SIMPLE_PRODUCTS")
  const hiddenVariants = parseShopifyReferencesFromMetatield(hiddenVariantsForProduct) ?? []

  const market = getMarket(locale)

  const serializedVariants = variants
    .map((variant) => {
      const hiddenOnMarket =
        parseTextsFromMetafield(getVariantMetafieldValue(variant.metafields, "HIDDEN_ON_MARKET")) ?? []

      if (hiddenOnMarket.includes(market) || (variant.id && hiddenVariants.includes(variant.id))) {
        return null
      }

      return {
        id: variant.id,
        title: variant.title,
        availableForSale: variant.availableForSale,
        sku: variant.sku,
        price: serializePrice({
          locale,
          amount: variant.price?.amount,
          currencyCode: variant.price?.currencyCode,
        }),
        unserializedPrice: variant.price,
        compareAtPrice:
          (variant.compareAtPrice?.amount ?? 0) > 0
            ? serializePrice({
                locale,
                amount: variant.compareAtPrice?.amount,
                currencyCode: variant.compareAtPrice?.currencyCode,
              })
            : null,
        unserializedCompareAtPrice: variant.compareAtPrice,
        selectedOptions: variant.selectedOptions,
        components: variant.components,
        image: variant.image ? serializeShopifyImages([variant.image]) : null,
        quantityAvailable: variant.quantityAvailable,
      }
    })
    .filter(Boolean)

  return serializedVariants
}
