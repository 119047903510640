"use client"

import clsx from "clsx"
import { m } from "framer-motion"

import type { PropsWithClassName } from "~/@types/generics"
import { PRODUCT_CUSTOM_ATTRIBUTES } from "~/lib/shopify/constants"
import { useAddToCart } from "~/lib/shopify/hooks/useAddToCart"
import type { UnlikelyCartLineItemInput } from "~/lib/shopify/types"
import { useGetFreeProductInCart } from "~/data/free-products-in-cart/hook"
import { getFreeProductsEligibleInCart } from "~/data/free-products-in-cart/utils/get-free-products-eligible-in-cart"
import useGetCart from "~/hooks/cart/useGetCart"
import { AddToCartButton } from "~/components/ui/AddToCartButton"
import { HtmlRichText } from "~/components/ui/HtmlRichText"
import { Image } from "~/components/ui/Image"
import { useTranslate } from "~/providers/I18nProvider/hooks/useTranslate"

import { text } from "~/styles/variants"

import * as css from "./styles.css"

export type FreeProductsInCartProps = PropsWithClassName

function FreeProductsInCart({ className }: FreeProductsInCartProps) {
  const cart = useGetCart()
  const { data: freeProductsInCart } = useGetFreeProductInCart()

  const t = useTranslate()
  const freeProductInCart = getFreeProductsEligibleInCart(freeProductsInCart, cart)?.filter(
    (product) => !product.isInCart
  )?.[0]

  const { mutate: addCartLines, isLoading } = useAddToCart()

  if (!freeProductInCart) return null

  const { image, title, text: shopifyText, productVariant } = freeProductInCart

  const onClick = () => {
    if (!productVariant?.id) return

    const productLineItem = {
      quantity: 1,
      merchandiseId: productVariant?.id,
      attributes: [{ key: PRODUCT_CUSTOM_ATTRIBUTES.IS_FREE_PRODUCT_IN_CART, value: "true" }],
    } satisfies UnlikelyCartLineItemInput

    addCartLines([productLineItem])
  }

  return (
    <m.div
      className={clsx(className, css.FreeProductInCart)}
      key={`free-product-in-cart`}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3, ease: "easeInOut" }}
    >
      {image && (
        <Image
          noBackground
          {...image}
          className={css.image}
          ratio={productVariant?.id ? "85/115" : "1/1"}
          objectFit="cover"
        />
      )}
      <div>
        {title && (
          <h3
            className={text({
              design: "riposte-14",
              lineHeight: 1.45,
              letterSpacing: 0.6,
              color: "blue-deep",
              transform: "uppercase",
              weight: "medium",
            })}
          >
            {title}
          </h3>
        )}
        {shopifyText && (
          <div className={css.shopifyText}>
            <HtmlRichText>{shopifyText}</HtmlRichText>
          </div>
        )}
        {productVariant?.id && (
          <AddToCartButton
            className={css.addToCartButton}
            onClick={onClick}
            isLoading={isLoading}
            disabled={false}
            isOutOfStock={!productVariant?.availableForSale}
            price={null}
            variantId={productVariant.id}
            productName={null}
            size="default"
          >
            {t("product_card_add")}
          </AddToCartButton>
        )}
      </div>
    </m.div>
  )
}

export default FreeProductsInCart
