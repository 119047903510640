export const OPTION_COLOR = "Color"
export const OPTION_SIZE = "Taille"
export const OPTION_CONFORT = "Confort"
export const OPTION_AMOUNT = "Montant"
export const UNIVERSAL_COLOR_OPTION_VALUE = "Universal"
export const EVERY_OPTIONS_WITHOUT_COLOR = [OPTION_SIZE, OPTION_CONFORT, OPTION_AMOUNT] as const
export const EVERY_OPTIONS = [OPTION_COLOR, OPTION_SIZE, OPTION_CONFORT, OPTION_AMOUNT] as const

export const OPTIONS_PARAM = "options"
export const SIZE_PARAM = "size"
export const CONFORT_PARAM = "confort"
export const REMPLISSAGE_PARAM = "remplissage"
export const COLOR_PARAM = "color"
export const SEARCH_PARAM = "q"
export const DEBUG_PARAM = "debug"
export const CART_PARAM = "cart"
export const CART_OPEN_VALUE = "open"
export const PANEL_SIZE_AUTO_OPEN_PARAM = "panel"
