import { type UnlikelyVariant } from "@unlikelystudio/commerce-connector"

import type { InferReturn, Nullish } from "~/@types/generics"
import { getMarket } from "~/lib/i18n/utils/get-i18n"
import {
  type TFreeProductsInCartConditionsMetaobject,
  type TFreeProductsInCartMetaobject,
} from "~/lib/shopify/metaobjects/free-products-in-cart"
import { getSfMedia } from "~/lib/shopify/queries/get-sf-media"
import { getSfVariant } from "~/lib/shopify/queries/get-sf-variant"
import { metafieldRichTextToHTMLString } from "~/lib/shopify/utils/rich-text-metafield-to-html"
import { serializeShopifyImages } from "~/components/ui/Image/_data/serializer"

export type TFreeProductInCart = InferReturn<typeof serializeFreeProductInCart>

export async function serializeFreeProductInCart(
  {
    image,
    title,
    text,
    ranking_condition,
    hide_variant_on_specific_markets,
    ...conditions
  }: TFreeProductsInCartMetaobject,
  locale: Nullish<string>
) {
  const getMedia = image ? await getSfMedia({ withLocale: false, id: image }) : null
  const computedImage = getMedia ? serializeShopifyImages([getMedia?.media])[0] : null

  const serializedConditions = await serializeFreeProductsConditionsInCart(conditions, locale)

  const hiddenOnMarket = hide_variant_on_specific_markets ?? []

  if (!serializedConditions || hiddenOnMarket.includes(getMarket(locale))) {
    return null
  }

  return {
    image: computedImage ?? null,
    title: title,
    text: metafieldRichTextToHTMLString(text) ?? null,
    rankingCondition: ranking_condition,
    ...serializedConditions,
  }
}

export async function serializeFreeProductsConditionsInCart(
  {
    disabled,
    product: variantId,
    out_of_stock_quantity,
    minimum_cart_price_condition,
    products_condition,
    collections_condition,
  }: TFreeProductsInCartConditionsMetaobject,
  locale: Nullish<string>
) {
  const response = variantId
    ? await getSfVariant({
        id: variantId,
        includeQuantityAvailable: true,
        includeProduct: true,
        variantsFirst: 100,
        locale,
      })
    : null

  // Because i don't knwo why quantityAvailable is always null
  // It's working if i do a TU inside connector with this params, WTF
  const variant = response?.variant?.product?.variants.find((v) => v.id === variantId)

  if (disabled || isFreeProductVariantDisabled(variant, out_of_stock_quantity)) {
    return null
  }

  return {
    productVariant: variant ?? null,
    minimumCartPriceCondition: minimum_cart_price_condition,
    productsCondition: products_condition ?? [],
    collectionsCondition: collections_condition ?? [],
  }
}

function isFreeProductVariantDisabled(variant: Nullish<UnlikelyVariant>, outOfStockQuantity: Nullish<number>) {
  return (
    variant &&
    (!variant?.availableForSale || (outOfStockQuantity && (variant?.quantityAvailable ?? 0) < outOfStockQuantity))
  )
}
