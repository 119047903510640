import type { UnlikelyCart } from "@unlikelystudio/commerce-connector"
import {
  useAddCartLines,
  useRemoveCartLines,
  useUpdateCartAttributes,
  useUpdateCartLines,
} from "@unlikelystudio/react-ecommerce-hooks"

import type { Nullish } from "~/@types/generics"
import { useLocale } from "~/lib/i18n/hooks/useLocale"
import {
  CHECKOUT_CUSTOM_ATTRIBUTES,
  CHECKOUT_ORIGIN_VALUE,
  getProductCustomAttributesValue,
  PRODUCT_CUSTOM_ATTRIBUTES,
} from "~/lib/shopify/constants"
import useLang from "~/hooks/useLang"
import useMarket from "~/hooks/useMarket"
import { getEmbroideryCustomAttributeData } from "~/components/ui/Product/ProductHeader/PanelEmbroidery/utils/get-embroidery-custom-attribute"
import { safeJSONParse } from "~/utils/safe-json-parse"

type MutationAddOnSuccess = Parameters<NonNullable<NonNullable<Parameters<typeof useAddCartLines>[0]>["onSuccess"]>>
type MutationUpdateOnSuccess = Parameters<
  NonNullable<NonNullable<Parameters<typeof useUpdateCartLines>[0]>["onSuccess"]>
>
type MutationRemoveOnSuccess = Parameters<
  NonNullable<NonNullable<Parameters<typeof useRemoveCartLines>[0]>["onSuccess"]>
>

type MutationAddOnError = Parameters<NonNullable<NonNullable<Parameters<typeof useAddCartLines>[0]>["onError"]>>
type MutationUpdateOnError = Parameters<NonNullable<NonNullable<Parameters<typeof useUpdateCartLines>[0]>["onError"]>>
type MutationRemoveOnError = Parameters<NonNullable<NonNullable<Parameters<typeof useRemoveCartLines>[0]>["onError"]>>

type MutationRemoveOnSettled = Parameters<
  NonNullable<NonNullable<Parameters<typeof useRemoveCartLines>[0]>["onSettled"]>
>

type UseAddToCartActions = {
  onSuccessAddLines?: (...params: MutationAddOnSuccess) => void
  onSuccessUpdateLines?: (...params: MutationUpdateOnSuccess) => void
  onSuccessRemoveLines?: (...params: MutationRemoveOnSuccess) => void
  onErrorAddLines?: (...params: MutationAddOnError) => void
  onErrorUpdateLines?: (...params: MutationUpdateOnError) => void
  onErrorRemoveLines?: (...params: MutationRemoveOnError) => void
  onSettledRemoveLines?: (...params: MutationRemoveOnSettled) => void
}

export function useCartActions(params?: UseAddToCartActions) {
  const {
    onSuccessAddLines,
    onSuccessUpdateLines,
    onSuccessRemoveLines,
    onErrorAddLines,
    onErrorUpdateLines,
    onErrorRemoveLines,
    onSettledRemoveLines,
  } = params ?? {}

  const lang = useLang()
  const market = useMarket()
  const locale = useLocale()

  const { mutateAsync: updateAttributes, isLoading: isLoadingAction } = useUpdateCartAttributes()

  async function onActionSuccess({ cart }: { cart: Nullish<UnlikelyCart> }) {
    if (!cart) {
      return
    }

    // Getting the existing cart attributes to overload them if needed
    const currentCartAttributes =
      cart?.attributes?.flatMap((attribute) => {
        if (attribute.value === null) {
          return []
        }
        return {
          key: attribute.key,
          value: attribute.value,
        }
      }) ?? []

    // Getting the cart lines and getting their tracking data
    const trackingDataAttributes =
      cart?.lines
        ?.map((lineItem) => {
          const lineItemTrackingData = getProductCustomAttributesValue(lineItem.attributes, "TRACKING_DATA")

          if (lineItemTrackingData) {
            return JSON.parse(lineItemTrackingData)
          }
          return null
        })
        ?.filter(Boolean) ?? []

    // Getting the embroidery attributes
    const embroideryAttributes =
      cart?.lines
        ?.map((lineItem) => {
          const lineItemEmbroideryData = getProductCustomAttributesValue(lineItem.attributes, "IS_EMBROIDERY_PRODUCT")

          if (lineItemEmbroideryData) {
            const lineItemEmbroideryAttributes = getProductCustomAttributesValue(
              lineItem.attributes,
              "UNIQ_EMBROIDERY_ATTRIBUTES"
            )

            const lineItemEmbroiderySKUAttributes = getProductCustomAttributesValue(
              lineItem.attributes,
              "EMBROIDERY_EMBRODABLE_PRODUCT_VARIANT_SKU"
            )

            if (lineItemEmbroideryAttributes && lineItemEmbroiderySKUAttributes) {
              const embroideryAttributes = getEmbroideryCustomAttributeData({
                embroideryDataAttribute: lineItemEmbroideryAttributes,
                embroidableVariantSKUAttribute: lineItemEmbroiderySKUAttributes,
              })

              return embroideryAttributes
            }

            return null
          }

          return null
        })
        ?.filter(Boolean) ?? []

    // Getting the bundle attributes
    const bundleAttributes =
      cart?.lines
        ?.map((lineItem) => {
          const lineItemBundleData = getProductCustomAttributesValue(lineItem.attributes, "BUNDLE_PRODUCT")

          if (lineItemBundleData) {
            const lineItemBundleIsFromShopTheLook =
              getProductCustomAttributesValue(lineItem.attributes, "IS_FROM_SHOP_THE_LOOK") === "true"

            const compiledBundleAttributes = {
              [PRODUCT_CUSTOM_ATTRIBUTES.PRODUCT_URL]: getProductCustomAttributesValue(
                lineItem.attributes,
                "PRODUCT_URL"
              ),
              [PRODUCT_CUSTOM_ATTRIBUTES.PRODUCT_COLOR]: getProductCustomAttributesValue(
                lineItem.attributes,
                "PRODUCT_COLOR"
              ),
              [PRODUCT_CUSTOM_ATTRIBUTES.COLLECTION]: getProductCustomAttributesValue(
                lineItem.attributes,
                "COLLECTION"
              ),
              [PRODUCT_CUSTOM_ATTRIBUTES.BUNDLE_PRODUCT]: safeJSONParse(lineItemBundleData),
              [PRODUCT_CUSTOM_ATTRIBUTES.PRODUCT_ID]: lineItem?.merchandise?.product?.id,
              [PRODUCT_CUSTOM_ATTRIBUTES.TRACKING_DATA]: safeJSONParse(
                getProductCustomAttributesValue(lineItem.attributes, "TRACKING_DATA")
              ),
              [PRODUCT_CUSTOM_ATTRIBUTES.IS_FROM_SHOP_THE_LOOK]: lineItemBundleIsFromShopTheLook,
              [PRODUCT_CUSTOM_ATTRIBUTES.IS_BUNDLE]: true,
            }

            return compiledBundleAttributes
          }

          return null
        })
        ?.filter(Boolean) ?? []

    await updateAttributes({
      cartId: cart.id,
      attributes: [
        ...currentCartAttributes,
        {
          key: CHECKOUT_CUSTOM_ATTRIBUTES.CHECKOUT_EMBROIDERY_DATA,
          value: JSON.stringify(embroideryAttributes),
        },
        {
          key: CHECKOUT_CUSTOM_ATTRIBUTES.CHECKOUT_PRODUCT_TRACKING_DATA,
          value: JSON.stringify(trackingDataAttributes),
        },
        {
          key: CHECKOUT_CUSTOM_ATTRIBUTES.CHECKOUT_BUNDLE_DATA,
          value: JSON.stringify(bundleAttributes),
        },
        /* Adding custom attributes to the cart. */
        {
          key: CHECKOUT_CUSTOM_ATTRIBUTES.CHECKOUT_ORIGIN,
          // Cart origin -> as headless
          value: CHECKOUT_ORIGIN_VALUE,
        },
        /* Adding a custom attribute cartLang to the cart. */
        {
          key: CHECKOUT_CUSTOM_ATTRIBUTES.CHECKOUT_LANG,
          value: lang,
        },
        /* Adding a custom attribute cartLocale to the cart. */
        {
          key: CHECKOUT_CUSTOM_ATTRIBUTES.CHECKOUT_LOCALE,
          value: locale,
        },
        /* Adding a custom attribute cartMarket to the cart. */
        {
          key: CHECKOUT_CUSTOM_ATTRIBUTES.CHECKOUT_MARKET,
          value: market,
        },
      ],
    })
  }

  /* The `const addLinesToCartAction` is a function that is using the `useAddCartLines` hook. This
  function is responsible for adding lines to the cart. */
  const addLinesToCartAction = useAddCartLines({
    onSuccess: (...params) => {
      onActionSuccess(params[0])

      onSuccessAddLines?.(...params)
    },
    onError: onErrorAddLines,
  })

  /* The `const removeLinesFromCartAction` is a constant that is assigned the result of calling the
  `useRemoveCartLines` hook. This hook is used to remove lines from the cart. */
  const removeLinesFromCartAction = useRemoveCartLines({
    onSuccess: (...params) => {
      onActionSuccess(params[0])

      onSuccessRemoveLines?.(...params)
    },
    onError: onErrorRemoveLines,
    onSettled: onSettledRemoveLines,
  })

  /* The `const updateCartLinesAction` is a constant that is assigned the result of calling the
  `useUpdateCartLines` hook. This hook is used to update the lines in the cart. */
  const updateCartLinesAction = useUpdateCartLines({
    onSuccess: (...params) => {
      onActionSuccess(params[0])

      onSuccessUpdateLines?.(...params)
    },
    onError: onErrorUpdateLines,
  })

  return {
    isLoadingAction,
    addLinesToCartAction,
    removeLinesFromCartAction,
    updateCartLinesAction,
    onActionSuccess,
  }
}
