import clsx from "clsx"

import type { Nullish } from "~/@types/generics"
import { Stack, type StackProps } from "~/components/abstracts/Stack"

import { sprinkles } from "~/styles/sprinkles.css"
import { text } from "~/styles/variants"

type SummaryItemProps = {
  title: string
  item: Nullish<string>
} & StackProps

export function SummaryItem({ title, item, sprinklesCss, ...props }: SummaryItemProps) {
  return (
    <Stack
      sprinklesCss={{
        pY: 10,
        borderColor: "grey-border",
        borderStyle: "solid",
        borderTopWidth: 1,
        justifyContent: "space-between",
        alignItems: "center",
        ...(sprinklesCss ?? {}),
      }}
      gap={20}
      {...props}
    >
      <p className={text({ design: "riposte-14", lineHeight: 1.45, letterSpacing: 0.2 })}>{title}</p>
      {item && (
        <div
          className={clsx(
            text({ design: "riposte-13", lineHeight: 1.5 }),
            sprinkles({
              pT: 5,
              pB: 6,
              pX: 6,
              bgColor: "grey-bg-page",
              borderRadius: 4,
              color: "blue-med",
            })
          )}
        >
          {item}
        </div>
      )}
    </Stack>
  )
}
