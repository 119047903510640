import { ShopifyProductSortKeys, type UnlikelyProduct } from "@unlikelystudio/commerce-connector"

import type { Nullish } from "~/@types/generics"
import { getProductMetafieldProccessedKeys } from "~/lib/shopify/constants"
import { getShopifyProductsByIds } from "~/lib/shopify/queries/get-sf-product-by-ids"
import { serializeAssociatedProductColors } from "~/components/ui/Product/ProductHeader/_data/serialize-associated-products-colors"
import getAssociatedProductsIdsFromProduct from "~/utils/get-associated-products-ids-from-product"

export async function getAssociatedProductColors(product: Nullish<UnlikelyProduct>, locale: Nullish<string>) {
  const associatedProductsIds = await getAssociatedProductsIdsFromProduct(product, locale)

  const getAssociatedProducts = await getShopifyProductsByIds(associatedProductsIds, {
    locale,
    metafieldKeys: getProductMetafieldProccessedKeys("PRODUCT_COLOR"),
    sortKey: ShopifyProductSortKeys.Relevance,
  })

  //Need to keep the metaobject order
  const associatedProducts =
    associatedProductsIds
      .map((sortKey) => {
        return getAssociatedProducts.products.find((product) => product.id === sortKey)
      })
      .filter(Boolean) ?? []

  return serializeAssociatedProductColors(locale, associatedProducts)
}
