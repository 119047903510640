import { useQuery } from "@tanstack/react-query"

import { useLocale } from "~/lib/i18n/hooks/useLocale"
import { getFreeProductsInCart } from "~/data/free-products-in-cart/get"

export const freeProductInCartQueryKey = "free-product-in-cart"

export function useGetFreeProductInCart() {
  const locale = useLocale()

  return useQuery({
    queryKey: [freeProductInCartQueryKey],
    queryFn: () => getFreeProductsInCart(locale),
    refetchOnWindowFocus: true,
    refetchOnMount: false,
  })
}
