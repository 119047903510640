import type { GID, UnlikelyProduct } from "@unlikelystudio/commerce-connector"

import type { InferReturn, Nullish, SetPartialKeys } from "~/@types/generics"
import { getProductMetafieldValue } from "~/lib/shopify/constants"
import { productColorMetaobjectSchema } from "~/lib/shopify/metaobjects/product-color"
import { serializeMetaobject } from "~/lib/shopify/metaobjects/serializer"
import { getSfMedia } from "~/lib/shopify/queries/get-sf-media"
import { getSfMetaobject } from "~/lib/shopify/queries/get-sf-metaobject"
import { serializeShopifyImages } from "~/components/ui/Image/_data/serializer"

export type TProductColor = InferReturn<typeof serializeProductColor>

export async function serializeProductColor(
  product: Nullish<SetPartialKeys<Pick<UnlikelyProduct, "metafields" | "availableForSale">, "availableForSale">>,
  locale: Nullish<string>,
  withMedia = true
) {
  const productColorMetaobjectId =
    product?.metafields && (getProductMetafieldValue(product.metafields, "PRODUCT_COLOR") as Nullish<GID>)

  if (!productColorMetaobjectId) {
    return null
  }

  const productColorMetaobject = await getSfMetaobject({ id: productColorMetaobjectId, locale })
  const serializedMetaobject = serializeMetaobject(productColorMetaobjectSchema, productColorMetaobject?.metaobject)

  if (!serializedMetaobject) {
    return null
  }

  const imageGid = serializedMetaobject.color_dot_image

  const { media } = withMedia ? await getSfMedia({ withLocale: false, id: imageGid }) : { media: null }
  const computedImage = withMedia ? serializeShopifyImages([media])[0] : null

  return {
    image: computedImage,
    value: serializedMetaobject.color_title,
    availableForSale: product?.availableForSale ?? false,
  }
}
