import type { Dictionary } from "~/providers/I18nProvider/constants"

export type EmplacementType = "taies" | "peignoirs"

type labelProps = {
  label: keyof Dictionary
}

type PlacementDbProps = {
  none: Partial<{
    [key in EmplacementType]: labelProps
  }>
  center: Partial<{
    [key in EmplacementType]: labelProps
  }>
}

export const PLACEMENTS_DB = {
  none: {
    taies: {
      label: "embroidery_placement_top_left",
    },
    peignoirs: {
      label: "embroidery_placement_sleeve",
    },
  },
  center: {
    taies: {
      label: "embroidery_placement_center",
    },
    peignoirs: {
      label: "embroidery_placement_heart",
    },
  },
} satisfies PlacementDbProps
