"use client"

import { useEffect, useRef, useState } from "react"
import clsx from "clsx"

import useGetCart from "~/hooks/cart/useGetCart"
import BottomCart from "~/components/globals/Cart/components/BottomCart"
import EmptyCart from "~/components/globals/Cart/components/EmptyCart"
import FullCart from "~/components/globals/Cart/components/FullCart"
import { PanelTop } from "~/components/globals/Panel/components/PanelTop"
import { serializeCartPayload } from "~/providers/GTMTrackingProvider/constants"
import { useTracking } from "~/providers/GTMTrackingProvider/hooks/use-tracking"
import { useTranslate } from "~/providers/I18nProvider/hooks/useTranslate"
import { useCart } from "~/managers/CartManager"
import { Panel } from "~/managers/PanelManager"

import * as css from "./styles.css"

function Default() {
  const cart = useCart()
  const { isFilledCart, cartArticlesNumber, cart: cartData } = useGetCart()
  const cartPanelRef = useRef(null)
  const t = useTranslate()
  const { sendEvent, sendResetEvent } = useTracking()
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (cartData) {
      sendResetEvent()
      sendEvent("view_cart", { ecommerce: serializeCartPayload(cartData) })
    }
  }, [])

  return (
    <Panel zIndex={50} clickOutsideRef={cartPanelRef}>
      <div className={clsx(css.Cart)} ref={cartPanelRef}>
        <PanelTop title={`${t("cart_title")} ${cartArticlesNumber ? `(${cartArticlesNumber})` : ""}`} largeFont />
        {cart && (
          <>
            {!isFilledCart ? (
              <EmptyCart empty={cart.emptyCart} className={clsx(css.emptyCart, css.spacing)} />
            ) : (
              <>
                <FullCart
                  setIsLoading={setIsLoading}
                  toOffer={cart.toOffer}
                  freeDeliveryGauge={cart.freeDeliveryGauge}
                  productSlider={cart.productSlider}
                  giftPanel={cart.giftPanel}
                  globalEmbroideryPictos={cart.globalEmbroideryPictos}
                />
              </>
            )}
            <BottomCart
              emptyCart={!isFilledCart}
              emptyCartLabelButton={cart.emptyCart.emptyCartLabelButton}
              reassurance={cart.reassurance}
              isLoading={isLoading}
            />
          </>
        )}
      </div>
    </Panel>
  )
}

export default Default
