"use client"

import type { ComponentProps } from "react"
import clsx from "clsx"

import { FONTS_DB } from "~/components/ui/Product/ProductHeader/PanelEmbroidery/EmbroideryForm/EmbroideryFontSelector/constants"
import { Stack } from "~/components/abstracts/Stack"
import { objectKeys } from "~/utils/object-keys"

import { sprinkles } from "~/styles/sprinkles.css"

import * as css from "./styles.css"

export const FONTS = objectKeys(FONTS_DB)

type EmbroideryFontSelectorProps = {
  currentFont: (typeof FONTS)[number] | null
  onChange: (newFont: (typeof FONTS)[number]) => void
} & ComponentProps<typeof Stack>

export function EmbroideryFontSelector({ currentFont, onChange, ...props }: EmbroideryFontSelectorProps) {
  return (
    <Stack gap={12} direction="row" sprinklesCss={{ flexWrap: "wrap" }} {...props}>
      {FONTS?.map((font, index) => {
        const isActive = currentFont === font

        return (
          <button
            key={`item_font_${index}`}
            type="button"
            onClick={() => {
              onChange(font)
            }}
            className={clsx(
              css.FontSelectorItem,
              css.fontVariants[font],
              sprinkles({
                borderColor: isActive ? "blue-deep" : "grey-light-bg",
                pX: 16,
                pY: 10,
                backgroundColor: "grey-light-bg",
                borderStyle: "solid",
                cursor: "pointer",
                borderWidth: 1,
              })
            )}
          >
            {font}
          </button>
        )
      })}
    </Stack>
  )
}
