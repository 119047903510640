import type { GID, UnlikelyProduct } from "@unlikelystudio/commerce-connector"

import type { Nullish } from "~/@types/generics"
import { getProductMetafieldValue } from "~/lib/shopify/constants"
import { globalAssociatedProductsMetaobjectSchema } from "~/lib/shopify/metaobjects/associated-products"
import { serializeMetaobject } from "~/lib/shopify/metaobjects/serializer"
import { getSfMetaobject } from "~/lib/shopify/queries/get-sf-metaobject"

export default async function getAssociatedProductsIdsFromProduct(
  product: Nullish<UnlikelyProduct>,
  locale: Nullish<string>
) {
  const associatedProductsMetaobjectsId =
    product && (getProductMetafieldValue(product.metafields, "ASSOCIATED_PRODUCTS") as Nullish<GID>)

  if (!associatedProductsMetaobjectsId) return []

  // Associated Product
  // For the variant PDP "like Le peignoir"
  // We need to associate all the products for have thos dot color variants and link associated
  const { metaobject } = await getSfMetaobject({ id: associatedProductsMetaobjectsId, locale })
  const associatedProductsMetaobject = serializeMetaobject(globalAssociatedProductsMetaobjectSchema, metaobject)

  if (!associatedProductsMetaobject?.products || associatedProductsMetaobject.products.length < 0) return []

  return associatedProductsMetaobject.products
}
