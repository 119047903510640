import type { Nullish } from "~/@types/generics"

function metafieldRichTextToHTMLString(content: Nullish<string>): string {
  const parsed = content ? (JSON.parse(content) as SfRichtextNode) : null

  if (!parsed || !parsed?.children) return ""

  return parseNodes(parsed.children)
}

export { metafieldRichTextToHTMLString }

type SfRichtextBase = {
  bold?: boolean
  italic?: boolean
}

type SfRichtextHeading = SfRichtextBase & {
  type: "heading"
  level: 1 | 2 | 3 | 4 | 5 | 6
}

type SfRichtextList = SfRichtextBase & {
  type: "list"
  listType: "ordered" | "unordered"
}

type SfRichtextListItem = SfRichtextBase & {
  type: "list-item"
}

type SfRichtextParagraph = SfRichtextBase & {
  type: "paragraph"
}

type SfRichtextLink = SfRichtextBase & {
  type: "link"
  url: string
  title?: Nullish<string>
  target?: Nullish<string>
}

type SfRichtextText = SfRichtextBase & {
  type: "text"
  value: string
}

type SfRichtext =
  | SfRichtextText
  | SfRichtextHeading
  | SfRichtextList
  | SfRichtextListItem
  | SfRichtextParagraph
  | SfRichtextLink

type SfRichtextNode = SfRichtext & { children?: SfRichtext[] }

function parseNodes(nodes: SfRichtextNode[]): string {
  return nodes?.map(parseNode).join("") ?? ""
}

function parseNode(node: SfRichtextNode): string {
  const children = parseChildren(node)

  switch (node.type) {
    case "heading":
      return `<h${node.level}>${children}</h${node.level}>`
    case "list": {
      const tag = node.listType === "unordered" ? "ul" : "ol"
      return `<${tag}>${children}</${tag}>`
    }
    case "list-item":
      return `<li>${children}</li>`
    case "paragraph":
      return `<p>${children}</p>`
    case "link":
      return `<a href="${node.url}" ${node.title ? `title="${node.title}"` : ""} ${
        node.target ? `target="${node.target}"` : ""
      }>${children}</a>`
    case "text":
      return children
  }
}

function parseChildren(node: SfRichtextNode): string {
  const children = "value" in node ? node.value : node?.children ? parseNodes(node.children) : ""

  if (node.bold && node.italic) {
    return `<strong><em>${children}</em></strong>`
  }

  if (node.bold) {
    return `<strong>${children}</strong>`
  }

  if (node.italic) {
    return `<em>${children}</em>`
  }

  return children
}
