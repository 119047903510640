import type { UnlikelyProduct } from "@unlikelystudio/commerce-connector"

import type { Nullish } from "~/@types/generics"
import { serializeVariants } from "~/lib/shopify/serializers/serialize-variants"
import { handleZodSafeParse } from "~/lib/zod/utils/handle-zod-safe-parse"
import { linkSchema } from "~/components/ui/Link/_data/schema"
import serializeLink from "~/components/ui/Link/_data/serializer"
import { serializeProductColor } from "~/components/ui/Product/ProductHeader/_data/serialize-product-color"
import type { TColorWithDotImage } from "~/components/ui/Product/ProductHeader/ColorsSelector"

export async function serializeAssociatedProductColors(
  locale: Nullish<string>,
  associatedProducts?: Nullish<UnlikelyProduct[]>
) {
  const associatedProductsColorsWithDotImage = (
    await Promise.all(
      associatedProducts?.map(async (associatedProduct) => {
        const parsedLink = handleZodSafeParse(linkSchema, associatedProduct, {
          errorContext: `Associated Product Link: ${associatedProduct?.handle}`,
        })

        const allVariantsUnavailable = associatedProduct?.variants?.every(
          (variant) => variant.availableForSale === false
        )

        const productColor = await serializeProductColor(associatedProduct, locale)

        const firstVariant = associatedProduct?.variants?.find(
          (variant) => (allVariantsUnavailable && productColor) || (variant.availableForSale && productColor)
        )

        const variant = firstVariant ? serializeVariants(associatedProduct, [firstVariant], locale) : null

        const colorWithDotImage = variant ? productColor : null

        const link = parsedLink ? serializeLink(parsedLink, locale, false) : null

        if (!colorWithDotImage) {
          return null
        }

        return {
          ...colorWithDotImage,
          link,
        } satisfies TColorWithDotImage
      }) ?? []
    )
  ).filter(Boolean)

  return associatedProducts && associatedProducts.length > 0 ? associatedProductsColorsWithDotImage : null
}
