import type { Nullish } from "~/@types/generics"

export function safeJSONParse<ReturnTypeValue = unknown>(stringifiedJSON: Nullish<string>) {
  try {
    if (!stringifiedJSON) {
      return null
    }

    return JSON.parse(stringifiedJSON) as ReturnTypeValue
  } catch (error) {
    console.error("JSON parsing error: ", error)
    return null
  }
}
