"use client"

import type { ComponentProps } from "react"
import clsx from "clsx"

import {
  PLACEMENTS_DB,
  type EmplacementType,
} from "~/components/ui/Product/ProductHeader/PanelEmbroidery/EmbroideryForm/EmbroideryPlacementSelector/constants"
import { Stack } from "~/components/abstracts/Stack"
import { useTranslate } from "~/providers/I18nProvider/hooks/useTranslate"
import { objectKeys } from "~/utils/object-keys"

import { sprinkles } from "~/styles/sprinkles.css"
import { text } from "~/styles/variants/text.css"

import * as css from "./styles.css"

export const PLACEMENTS = objectKeys(PLACEMENTS_DB)

type EmbroideryPlacementSelectorProps = {
  currentPlacement: string | null
  onChange: (newPlacement: (typeof PLACEMENTS)[number]) => void
  embroideryPlacementType: EmplacementType
} & ComponentProps<typeof Stack>

export function EmbroideryPlacementSelector({
  currentPlacement,
  onChange,
  embroideryPlacementType,
  ...props
}: EmbroideryPlacementSelectorProps) {
  const t = useTranslate()

  return (
    <Stack gap={12} sprinklesCss={{ flexWrap: "wrap" }} {...props}>
      {PLACEMENTS?.map((placement, index) => {
        const isActive = currentPlacement === placement

        return (
          <button
            key={`item_font_${index}`}
            type="button"
            onClick={() => {
              onChange(placement)
            }}
            className={clsx(
              css.PlacementSelectorItem,
              text({ design: "riposte-12", transform: "uppercase", color: "black" }),
              sprinkles({
                borderColor: isActive ? "blue-deep" : "grey-light-bg",
                pX: 16,
                pY: 11,
                backgroundColor: "grey-light-bg",
                borderStyle: "solid",
                cursor: "pointer",
                borderWidth: 1,
              })
            )}
          >
            {t(PLACEMENTS_DB[placement][embroideryPlacementType].label)}
          </button>
        )
      })}
    </Stack>
  )
}
