import clsx from "clsx"

import Slider, { useSliderState } from "@unlikelystudio/react-slider"

import { Image } from "~/components/ui/Image"
import type { TImage } from "~/components/ui/Image/_data/serializer"
import { SliderArrowNavigation } from "~/components/ui/Slider/SliderArrowNavigation"
import { SliderBullets } from "~/components/ui/Slider/SliderBullets"
import { Stack, type StackProps } from "~/components/abstracts/Stack"

import { sprinkles } from "~/styles/sprinkles.css"
import { breakpoints } from "~/styles/variables/breakpoints"

const IMAGE_SIZES = [{ breakpoint: breakpoints.md, ratio: 2 / 3 }, { ratio: 1 }]

type PanelEmbroiderySliderImagesProps = {
  images: TImage[]
  sliderItemClassName: string
} & StackProps

export function PanelEmbroiderySliderImages({
  images,
  sliderItemClassName,
  className,
  sprinklesCss,
  ...props
}: PanelEmbroiderySliderImagesProps) {
  const [{ maxSlideIndex, slideIndex, enableDrag, nextSlide, prevSlide }, setSliderState] = useSliderState()

  return (
    <Stack
      className={clsx(
        className,
        sprinkles({
          position: "relative",
          alignItems: { mobile: "flex-end", tablet: "center" },
          height: "100%",
          ...(sprinklesCss ?? {}),
        })
      )}
      {...props}
    >
      <Slider
        className={sprinkles({ height: { tablet: "100%" } })}
        containerClassName={sprinkles({ height: { tablet: "100%" } })}
        setSliderState={setSliderState}
        snap
        maxSlideIndexChange={1}
      >
        {images?.map((image, index) => (
          <Image
            {...image}
            ratio={{ mobile: "1/1", tablet: "unset" }}
            className={clsx(sliderItemClassName, sprinkles({ height: { tablet: "100%" } }))}
            key={`embroidery_slider_${index}`}
            sizesFromBreakpoints={IMAGE_SIZES}
            objectFit="cover"
            imageHeight="100%"
            asPlaceholder
          />
        ))}
      </Slider>
      {enableDrag && (
        <>
          <SliderArrowNavigation
            showFrom="tablet"
            sprinklesCss={{ position: "absolute", justifyContent: "space-between", width: "100%", pX: 30 }}
            onNextClick={nextSlide}
            onPrevClick={prevSlide}
            prevDisabled={slideIndex === 0}
            nextDisabled={slideIndex === maxSlideIndex}
          />
          <SliderBullets
            hideFrom="tablet"
            sprinklesCss={{ position: "absolute", justifyContent: "center", bottom: 20, left: 0, right: 0 }}
            currentIndex={slideIndex}
            totalItems={maxSlideIndex + 1}
          />
        </>
      )}
    </Stack>
  )
}
